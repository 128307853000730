/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
//Import assets
import golf from '../assets/video/GOLF.mp4'
import kribiza from '../assets/video/MAHOPTION KRIBIZAR SOIREE.mp4'
import maireV from "../assets/video/RESUME-EVENT-AUTOSTOP-RALLYE-GT-2.mp4"
import promo from '../assets/img/contexteImg.jpeg'

// Import slides images;
import im1 from '../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.22.34_IMG_4565_LR.webp'
import im2 from '../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.02.46_IMG_5728_LR.webp';
import im3 from '../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.50.44_IMG_5590_LR.webp'
import im4 from '../assets/img/EA_RALLYE_ALBUM_1/MAIRE DE DOUALA.webp'
import im5 from '../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6838_LR.webp';


import scobyV from '../assets/video/partner/EAR_TEAS-SCOBY_2024.mp4'
import jingV from '../assets/video/partner/EAR_TEAS-PAUL_JING_2024.mp4'
import epeeV from '../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_JUNIOR_EPEE_2024.mp4'
import djaguiV from '../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_PAUL_DJAGUI_2024.mp4'
import wadihV from '../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_WADDIE_ASSAD_ 2024.mp4'
import tchamakoV from '../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_JOEL_TCHAMAKO_2024.mp4'
import canalV from '../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_TEAS_CANAL+_2024.mp4'
import bouzemareneV from '../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_P.D.T_MAURICE_BOUZEMARENE_2024.mp4'
import rogaV from '../assets/video/partner/EAR_TEAS-ROGA_ROGA_2024.mp4'
import tchoptchopV from '../assets/video/partner/EAR_TEAS-PRESIDENT_TCHOP-TCHOP_2024.mp4'
import ambattaV from '../assets/video/partner/EAR_TEAS-HERVE_PATRICK_AMBATTA_2024.mp4'
import nyodogV from '../assets/video/partner/EAR_TEAS-PERRIAL_NYODOG_2024.mp4'
import tembiwaV from '../assets/video/partner/EAR_TEAS-XAVIER_TEMBIWA_2024.mp4'
import tinenV from '../assets/video/partner/EAR_TEAS-HILAIRE_TINEN_2024.mp4'
import ndoumbeV from '../assets/video/partner/EAR_TEAS-GUY_NDOUMBE_2024.mp4'
import tjouesV from '../assets/video/partner/EAR_TEAS-JACQUES_TJOUES_2024.mp4'
import siarV from '../assets/video/partner/EAR_TEAS-CLAUDY_SIAR_2024.mp4'

import jingI from '../assets/img/partner/paul_jing.webp'
import epeeI from '../assets/img/partner/PHOTO_PILOTE_2024/junior_epee.png'
import djaguiI from '../assets/img/partner/PHOTO_PILOTE_2024/PAUL_DJANGUI.png'
import wadihI from '../assets/img/partner/PHOTO_PILOTE_2024/waddie_assad.png'
import tchamakoI from '../assets/img/partner/PHOTO_PILOTE_2024/joel_tchamako.png'
import canalI from '../assets/img/partner/PHOTO_PILOTE_2024/canalplus.png'
import bouzemareneI from '../assets/img/partner/PHOTO_PILOTE_2024/BOUZEMARENE.png'
import tinenI from '../assets/img/partner/tinen.jpg'
import ndoumbeI from '../assets/img/partner/ndoumbe.jpg'
import siarI from '../assets/img/partner/siar.jpg'
import tjouesI from '../assets/img/partner/tjoues.jpg'
import ambattaI from '../assets/img/partner/PATRICK_HERVE.jpg'
import scobyI from '../assets/img/partner/SCOBY.jpg'
import nyodogI from '../assets/img/partner/PERRIAL.jpg'
import tchoptchopI from '../assets/img/partner/TCHOP_TCHOP.jpg'
import rogaI from '../assets/img/partner/roga_roga.jpg'
import tembiwaI from '../assets/img/partner/xavier.png'



//Import images rotate
import r1 from '../assets/img/EAR_RALLYE_Japoma_2024/13.25.45_B_TEG_3848_LR.jpg';
import r2 from '../assets/img/EAR_RALLYE_Japoma_2024/17.19.14_C_IMG_0796_LR.jpg';
import r3 from '../assets/img/EAR_RALLYE_Japoma_2024/17.33.00_A_0M2A0999_LR.jpg';
import r4 from '../assets/img/EAR_RALLYE_Japoma_2024/17.14.12_A_TEG_4200_LR.jpg';
import r5 from '../assets/img/EAR_RALLYE_Japoma_2024/16.44.20_A_TEG_4028_LR.jpg';
import r6 from '../assets/img/EAR_RALLYE_Japoma_2024/14.13.04_C_IMG_0570_LR.jpg';
import r7 from '../assets/img/EAR_RALLYE_Japoma_2024/11.33.34_E_IMG_9742_LR.jpg';
import r8 from '../assets/img/EAR_RALLYE_Japoma_2024/09.49.44_A_0M2A9010_LR.jpg';


// Import news images
import horizon_camer from '../assets/img/news/japoma2024/horizon_camer.jpeg'
import camer_be from '../assets/img/news/japoma2024/camer_be.jpg'
import quatrieme_pouvoir from '../assets/img/news/japoma2024/quatrieme_pouvoir.png'
import cameroon_file from '../assets/img/news/japoma2024/cameroon_file.jpg'
import cameroon_file_vainqueur from '../assets/img/news/japoma2024/cameroon_file_vainquer.jpeg'
import quatrieme_pouvoir_vainqueur from '../assets/img/news/japoma2024/quatriemeP_vainqueur.jpeg'


//Import components
import Hero from '../components/ui/Hero'
import Title from '../components/ui/Title'
import { NavLink, useLocation } from 'react-router-dom'
import Carousel from  'react-multi-carousel'
import "react-multi-carousel/lib/styles.css"
import Modal from 'react-responsive-modal'
import 'react-responsive-modal/styles.css';
import Timer from '../components/ui/Timer'
import { AppContexte } from '../context/context'
import { URL_API, URL_API_SPONSORS } from '../constant/url'
import MetaDecorator from '../components/ui/MetaDecorator'
import { HashLoader } from 'react-spinners'
import axios from 'axios'
import SponsorCard from '../components/ui/SponsorCard'



const NewsCard = (props) => {
    return (
        <div className='col-lg-4 col-md-6 col-12 mb-4 align-items-stretch'>
            <div className='rounded-3 mb-3 card shadow h-100 position-relative bg-black text-white border '>
                <div className=' d-flex justify-content-center align-items-center' style={{"height":"13rem"}}>
                    <img alt={props.title} src={props.img}  className='rounded-3 object-fit-cover w-100 h-100' />
                </div>
                <div className=' card-body'>
                    <h5 className='aer-color text-center text-uppercase'> {props.title} </h5>
                    <p className=''> {props.description} </p>
                    <small className='fs-6 fw-thin text-uppercase'> {props.date} </small>
                    <br></br>
                </div>
                <div className=' card-footer border-0 mx-auto mb-2 '>
                    <a rel='noreferrer' target='_blank' href={props.link} className='color-primary text-decoration-none border border-1 border-warning rounded-pill py-1 px-3 fs-5'> Lire plus... </a>
                </div>
            </div>
        </div>
    )
}





const Home = () => {

    const { pathname, hash, key } = useLocation();
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
        window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ block: "start", inline: "start" });
            }
        }, 0);
        }
    }, [pathname, hash, key]); 


    const reload = async() =>{
        setLoadSponsor(true)
        axios
        .get(URL_API_SPONSORS)
        .then((response)=>{setSponsor(response.data.data); console.log(response); setLoadSponsor(false); setErrorSponsor(false)})
        .catch((err) => {setLoadSponsor(false); setErrorSponsor(true); console.log(err.message);})
    }

    const reading = {
        display : "flex",
        margin : "0 auto"
    }

    const {categorie, actu, loadSponsor, setLoadSponsor, sponsor, setSponsor, errorSponsor, setErrorSponsor,} = useContext(AppContexte)

    const business = actu.filter(elt => elt.id === categorie[0].best)
    const sport = actu.filter(elt => elt.id === categorie[1].best)
    const event = actu.filter(elt => elt.id === categorie[2].best)
    const decouverte = actu.filter(elt => elt.id === categorie[3].best)

    const array = [
        {img: event[0].image, title: categorie[2].label, text: event[0].title, path:`/actualite/${categorie[2].id}/${categorie[2].best}`},
        {img: business[0].image, title: categorie[0].label, text: business[0].title, path:`/actualite/${categorie[0].id}/${categorie[0].best}`},
        {img: sport[0].image, title: categorie[1].label, text: sport[0].title, path:`/actualite/${categorie[1].id}/${categorie[1].best}`},
        {img: decouverte[0].image, title: categorie[3].label, text: decouverte[0].title, path:`/actualite/${categorie[3].id}/${categorie[3].best}`},
    ]

    const [open, setOpen] = useState(false)
    const [vids, setVids] = useState(false)
    const [showF, setShowF] = useState(false)
    const [img, setImg] = useState(false)

    const responsive = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet:{
            breakpoint: { max: 1200, min: 767 },
            items: 3
        },
        mobilet:{
            breakpoint: { max: 992, min: 380 },
            items: 2
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 1
        },
    }

    const responsiveVids = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet:{
            breakpoint: { max: 1200, min: 767 },
            items: 1
        },
        mobilet:{
            breakpoint: { max: 972, min: 380 },
            items: 1
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 1
        },
    }

    const responsiveSponsor = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 8
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet:{
            breakpoint: { max: 1300, min: 767 },
            items: 4
        },
        mobilet:{
            breakpoint: { max: 992, min: 380 },
            items: 3
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 2
        },
    }

    const person = [
        {imag:jingI , nom:'Paul Jing', role:"Avocat d'affaire", vid:jingV},
        {imag:epeeI , nom:'Junior Epee', role:"DG de SHOOTING STAR", vid:epeeV},
        {imag:djaguiI , nom:'Paul Djagui', role:"Fondateur de CIPRENGROUP", vid:djaguiV},
        {imag:wadihI , nom:'Wadih Assad', role:"PDG de 3S Motors", vid:wadihV},
        {imag:tchamakoI , nom:'Joel Tchamako', role:"PDG de GAP MOTORS", vid:tchamakoV},
        {imag:canalI , nom:'Canal +', role:"Chaine de télé", vid:canalV},
        {imag:bouzemareneI , nom:'Maurice Bouzemarene', role:"Directeur Générale de Olea Cameroun", vid:bouzemareneV},
        {imag:tembiwaI , nom:'Xavier Tembiwa', role:"PDG de AFRIGAZ", vid:tembiwaV},
        // {imag:tchoptchopI , nom:'PDT Tchop-Tchop', role:"PDG de JAMBO MEDIA", vid:tchoptchopV},
        // {imag:ambattaI , nom:'Herve Ambatta', role:"Directeur DIRPOMS CUD", vid:ambattaV},
        // {imag:ndoumbeI , nom:'Guy Ndoumbe', role:"Directeur général de H-CODE", vid:ndoumbeV},
        // {imag:tinenI, nom:'hilaire tinen', role:"P.D.G de CAMTRACK", vid:tinenV},
        // {imag:tjouesI , nom:'Jacques Olivier', role:"Homme d'affaires", vid:tjouesV},
        // {imag:siarI , nom:'claudy siar', role:'Présentateur, Producteur / Journaliste: RFI', vid:siarV},
        // {imag:nyodogI , nom:'Perrial Nyodog', role:"Président de GULFCAM", vid:nyodogV},
        // {imag:scobyI , nom:'Auguste Scoby', role:"Pilote de ligne", vid:scobyV},
        // {imag:rogaI, nom:'Roga Roga', role:"Artiste Musicien", vid:rogaV},
    ]

    const imgRallye=[
        {img: im1,},
        {img: im2,},
        {img: im3,},
        {img: im4,},
        {img: im5,},
    ]


    const imagesRotate=[
        {img: r1},
        {img: r2},
        {img: r3},
        {img: r4},
        {img: r5},
        {img: r6},
        {img: r7},
        {img: r8},
    ]


    const news =[
        {image:cameroon_file_vainqueur, title:"Ear Rallye GT : Paul Jing s’impose une fois de plus", date:"28 Février 2024", description:"Paul Jing est le grand vainqueur de la deuxième édition de l’Ear Rallye Gt. Le pilote s’est imposé devant ses adversaires ce samedi, 24 février 2024 au cours de la deuxième édition de cette course automobile qui s’est courue sur une distance de 14,5 km dans et autour du stade de Japoma à Douala.", link:"https://cameroon-files.com/ear-rallye-gt-paul-jing-simpose-une-fois-de-plus/?noamp=mobile"},
        {image:quatrieme_pouvoir_vainqueur, title:"Rallye GT Douala 2024 : Une Victoire Électrisante pour Paul Jing", date:"29 Février 2024", description:"Paul Jing a une fois de plus prouvé sa maîtrise au volant en remportant le rallye pour la deuxième année consécutive. Sur sa Mercedes AMG GT63S, il a brillé en franchissant la ligne d’arrivée en 8 minutes et 12 secondes, se hissant ainsi au sommet du classement", link:"https://lequatriemepouvoir.com/rallye-gt-douala-2024-une-victoire-electrisante-pour-paul-jing/"},
        {image:quatrieme_pouvoir, title:"Le grand retour de EAR Rallye GT à Douala", date:"6 Février 2024", description:"Sous l'impulsion dynamique de Miss Mahop, l'agence Mahoption Ltd et le magazine Auto-stop unissent leurs talents pour célébrer l'automobile à travers cet événement mêlant performance automobile, élégance et engagement citoyen.", link:"https://lequatriemepouvoir.com/le-grand-retour-de-ear-rallye-gt-a-douala/"},
        {image:horizon_camer, title:"Le retour triomphant de l’EAR Rallye GT à Douala-Japoma", date:"6 Février 2024", description:"L’agence événementielle et de communication Mahoption Ltd, en collaboration avec le magazine Auto-stop, annonce avec enthousiasme le retour tant attendu de l’EAR Rallye GT à Douala-Japoma.", link:"https://horizoncamer.com/2024/02/06/sport-le-retour-triomphant-de-lear-rallye-gt-a-douala-japoma/"},
        {image:camer_be, title:"LE RALLYE EAR GT DÉBARQUE À DOUALA ", date:"6 Février 2024", description:"Le come-back de l'EAR Rallye GT à Douala du 24 au 25 février est une annonce qui réjouit à la fois l'agence Mahoption Ltd et le magazine Auto-stop. Après avoir connu un franc succès lors de sa première édition à Kribi l'an dernier, cette compétition de référence dans le milieu promet encore plus de sensations fortes.", link:"https://www.camer.be/84320/7:1/cameroun-sport-le-rallye-ear-gt-debarque-a-douala-cameroon.html"},
        {image:cameroon_file, title:"Après Kribi, l’Ear Gt débarque à Douala", date:"7 Février 2024", description:"Après Kribi en juillet 2023, Eventify auto stop rallye Gt (Ear) débarque à Douala, les 24 et 25 février 2024. L’annonce a été faite mardi, 6 février par le comité d’organisation de l’événement, au cours d’un conférence de presse.", link:"https://cameroon-files.com/sports-automobiles-apres-kribi-lear-gt-debarque-a-douala/"},
    ]

    return (
        <>
            <MetaDecorator
                title={"Accueil"}
                description={"Site web de Eventi Auto'Stop Rallye GT"}
                imageUrl={"https://ear-admin.eventify.cm/static/media/LOGO%20EAR%202024-B.878af34127719117fd68.png"}
            />



            <Hero/>
            <div className='pb-4'>
                <div className='container'>

                    <div id='home-timer'>
                        <div className=' container mb-2 py-2 rounded-top-0 rounded-3 timer'>
                            <div className='text-center mb-md-3 mb-0'>
                                <h1 className=' gradient-back text-uppercase pt-md-2 pt-0 fw-bolder m-0'>coming soon</h1>
                                <span className='text-uppercase spanTimer pt-2'>Prochaine édition 29 Novembre 2024</span>
                            </div>
                            <Timer/>
                            <div className='text-center py-1'>
                                <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/inscription/participant'}>
                                    <span className='fw-bold fs-4 gradient-back'>S'inscrire </span>
                                    <i className="fa fa-arrow-right arrow-register gradient-back-no" aria-hidden="true"></i>
                                </NavLink>
                            </div>
                        </div>
                    </div>

                    <div id='home-team'>
                        <Title text={
                        <>
                            <span>participants EAR 2024 Douala</span>
                            <br/>
                            <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/event/ear-kribi-2023'}>
                                <span className='fw-medium fs-4 gradient-back d-flex align-items-center'> Édition 2023 <i className="fa-solid fa-arrow-up-right-from-square fs-5 ms-1"></i></span>
                            </NavLink>
                        </>
                        } />
                        <Carousel responsive={responsive} showDots={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='container bg-transparent' >
                                {
                                    person.map((elt,index)=>(
                                        <div key={index} className='px-2 mb-4 position-relative h-100'>
                                            <div className='rounded-3 mb-3 border border-1 border-light bg-transparent card shadow-sm'>
                                                <div className='team-img'>
                                                    <div className='aer-img-team-mobile overflow-hidden'>
                                                        <img src={elt.imag} alt={elt.nom+ " " + elt.role} className='rounded-circle' />
                                                    </div>
                                                </div>
                                                <div className='px-1 gx-0 card-body text-center team-card mb-5'>
                                                    <h5 className='text-light text-uppercase'>{elt.nom}</h5>
                                                    <span className='color-primary text-center'>{elt.role}</span>
                                                    <br/>
                                                    <i role='button' className='fa-brands fa-youtube text-light fa-2x mb-sm-3 mb-1 btn-vid bg-transparent 'onClick={()=>{setOpen(true); setVids(elt.vid)}}></i>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                        </Carousel>
                        <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/event/ear-japoma-2024#japoma-pilotes'}>
                            <span className='fw-medium fs-4 gradient-back d-flex align-items-center justify-content-end'> Voir plus <i className="fa-solid fa-angle-right fs-5 ms-2 mt-1"></i></span>
                        </NavLink>
                    </div>

                    <div id='2024_album'>
                        <Title text="Images de l'EAR 2024" />
                        <div className='container d-flex justify-content-center align-items-start overflow-hidden'>
                            <div className="gallery-rotate">
                                {
                                    imagesRotate.map((elt,index)=>(
                                        <span onClick={()=>{setShowF(true); setImg(elt)}} role='button' key={index} style={{"--i":(index+1)}}>
                                            <img src={elt.img} alt="Event Auto'stop rallye GT 2024 japoma" />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                        <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/japoma/album_photo'}>
                            <span className='fw-medium fs-4 gradient-back d-flex align-items-center justify-content-end'> Plus d'images <i className="fa-solid fa-angle-right fs-5 ms-2 mt-1"></i></span>
                        </NavLink>
                    </div>

                    <div id="news">
                        <Title text={"Revue de presse"} />
                        <div className=' row justify-content-around d-md-flex d-none'>
                            {
                                news.map((elt,index)=>(
                                    <NewsCard key={index} img={elt.image} title={elt.title} description={elt.description} date={elt.date} link={elt.link} />
                                ))
                            }
                        </div>

                        <div className=' d-md-none'>
                            <Carousel responsive={responsive} showDots={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='container bg-transparent' >
                            {
                                news.map((elt,index)=>(
                                    <div className=' px-1'>
                                        <NewsCard key={index} img={elt.image} title={elt.title} description={elt.description} date={elt.date} link={elt.link} />
                                    </div>
                                ))
                            }
                            </Carousel>
                        </div>
                    </div>

                    <div id='sponsors'>
                        <Title text="Nos partenaires" />
                        {
                            loadSponsor?
                                <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                    <div className='text-center'>
                                        <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                                        <p className='mt-4'>Veuillez patienter...</p>
                                    </div>
                                </div>
                            :
                            errorSponsor?
                                <div className='w-100 h-100 d-flex justify-content-center align-items-center mb-5'>
                                    <div className='text-center'>
                                        <i className='ri-error-warning-fill ri-5x text-danger'></i>
                                        <p className=''>Une erreur c'est produite. <br/> Veuillez vérifier votre connexion internet et réessayer ! </p>
                                        <button onClick={reload} className='btn bg-transparent border rounded-circle'><i className=' ri-loop-right-line px-1 ri-3x'></i></button>
                                    </div>
                                </div>
                            :
                            sponsor?
                                sponsor.length ?
                                    <>
                                        <Carousel responsive={responsiveSponsor} centerMode={true} showDots={false} arrows={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='container bg-transparent black-arrow' >
                                            {
                                                sponsor.map((elt,index) =>(
                                                    <SponsorCard img={elt.image} width={'w-100 mx-1'} margin='mx-2' nom={elt.name} site={elt.lien} key={index} />
                                                ))
                                            } 
                                        </Carousel>
                                        <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/sponsors'}>
                                            <span className='fw-medium fs-4 gradient-back d-flex align-items-center justify-content-end'> Voir plus <i className="fa-solid fa-angle-right fs-5 ms-2 mt-1"></i></span>
                                        </NavLink>
                                    </>
                                :
                                <div className='w-100 h-100 d-flex justify-content-center align-items-center mb-5'>
                                    <div className='text-center'>
                                        <i className='ri-error-warning-fill ri-5x text-danger'></i>
                                        <p className=''>Veuillez patienter ! </p>
                                    </div>
                                </div>
                            :null
                        }
                    </div>

                    <div id='home-video' className='row flex-column justify-content-center align-items-center'>
                        <Title text={"Première édition vidéos"} />
                        <div className='col-12 row justify-content-center d-lg-flex d-none align-items-center'>
                            <div className='d-flex flex-column justify-content-center align-items-center col-md-4 col-12 h-100'>
                                <video controls muted loop autoPlay  poster={'/static/media/1 - Rallye_2023-07-01_10.22.36_IMG_4566_LR.979fc9b458ec3fce7745.webp'} preload="none" className='hero-vid'>
                                    <source src={maireV} type='video/mp4' />
                                </video>
                                <small className='color-primary fw-bold'>MOT DU MAIRE DE KRIBI</small>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center col-md-4 col-12 h-100'>
                                <video controls muted loop autoPlay  poster={'/static/media/2 - Soirée_2023-07-02_01.32.10_IMG_6050_LR.3c59a1151bc14db0f1a5.webp'} preload="none" className='hero-vid'>
                                    <source src={kribiza} type='video/mp4' />
                                </video>
                                <small className='color-primary fw-bold'>KRIBIZA</small>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center col-md-4 col-12 h-100'>
                                <video controls muted loop autoPlay  poster={'/static/media/3 - Golf_IMG_6838_LR.b3080c0c45a4f163a29d.webp'} preload="none"  className='hero-vid'>
                                    <source src={golf} type='video/mp4' />
                                </video>
                                <small className='color-primary fw-bold'>GOLF TOUR</small>
                            </div>
                        </div>

                        <div className='d-lg-none d-block'>
                            <Carousel responsive={responsiveVids} showDots={true} autoPlay={false} infinite={true} className='container bg-transparent' >
                                <div className='d-flex flex-column justify-content-center align-items-center mb-4 pe-3'>
                                    <video controls muted loop autoPlay poster={'/static/media/1 - Rallye_2023-07-01_10.22.36_IMG_4566_LR.979fc9b458ec3fce7745.webp'} preload="none"  className='hero-vid'>
                                        <source src={maireV} type='video/mp4' />
                                    </video>
                                    <small className='color-primary fw-bold'>MOT DU MAIRE DE KRIBI</small>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center mb-4 pe-3'>
                                    <video controls muted loop autoPlay poster={'/static/media/2 - Soirée_2023-07-02_01.32.10_IMG_6050_LR.3c59a1151bc14db0f1a5.webp'} preload="none"  className='hero-vid'>
                                        <source src={kribiza} type='video/mp4' />
                                    </video>
                                    <small className='color-primary fw-bold'>KRIBIZA</small>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center mb-4 pe-3'>
                                    <video controls muted loop autoPlay poster={'/static/media/3 - Golf_IMG_6838_LR.b3080c0c45a4f163a29d.webp'} preload="none"  className='hero-vid'>
                                        <source src={golf} type='video/mp4' />
                                    </video>
                                    <small className='color-primary fw-bold'>GOLF TOUR</small>
                                </div>
                            </Carousel>
                        </div>



                        {/* <div className='col-12'>
                            <div className='text-center'>
                                <NavLink className={'show-link text-decoration-none text-light'} to={'/2'}>
                                    <small>Voir plus</small>
                                    <i className="ms-2 ri-share-forward-fill fs-4"></i>
                                </NavLink>
                            </div>
                        </div> */}
                    </div>

                    <div id='magazine' className=''>
                        <Title text={"ear kribi 2023"}/>
                        {/* <iframe className=' border-0 h-100vh left-0 w-100 top-0' src="https://online.fliphtml5.com/ssivn/vcjp/"  seamless="seamless" scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen={true} title="Eventi Auto'Stop Magazine"></iframe> */}
                        <Carousel responsive={responsiveVids} showDots={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='container bg-transparent' >
                        {
                            imgRallye.map((elt, index)=>{
                                return(
                                    <div key={index} className='hero-slide'>
                                        <div className=' position-relative d-flex justify-content-center'>
                                            <div className=' position-absolute w-100 h-100 bg-dark bg-opacity-25'></div>
                                            <img alt='Event AutoStop Rallye GT 2023' src={elt.img} className='img-fluid img' />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </Carousel>
                    </div>

                    <div id='home-actualites'>
                    <Title text={'Actualités'} />
                        <div className='row gx-0 justify-content-md-between justify-content-center align-items-start '>
                            <div className='col-md-6 col-12 position-sticky mb-3 mb-md-0 top-stic reveal'>
                                <div className='position-relative'>
                                    <img src={promo} alt='promotrice' className='img-fluid'/>
                                    <div className='img-fluid d-flex justify-content-center align-items-end w-100 h-100 bg-darkp bg-promo position-absolute top-0'>
                                        <div className='px-3 gx-0 text-center'>
                                            <h3 className='text-white text-uppercase'>Miss Mahop</h3>
                                            <span className='aer-color text-capitalize fs-5'>Promotrice de Event Auto'Stop Rallye GT (EAR) </span>
                                            <h4 className='text-white fs-5 pb-5'>“ Il ne sert à rien de savoir faire sans le faire savoir ”</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 row justify-content-center">
                            {
                                array.map((elt, index)=> (
                                    <div className='col-12 px-0 px-md-2 mb-4 reveal' key={index} >
                                        <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={elt.path} className='row show-link'>
                                            <div className='p-0 position-relative'>
                                                <div className='w-100 overflow-hidden actu-img'>
                                                    <img src={`${URL_API}${elt.img}`} alt={elt.title+ " "+elt.text} className='img-fluid'/>
                                                </div>
                                                <div className='bg-actu position-absolute p-2 bg-black bg-opacity-75'>
                                                    <h5 className='text-white m-0 text-uppercase fw-medium'>{elt.title}</h5>
                                                    <small className='color-primary'>{elt.text} </small>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <Modal open={open} onClose={()=>{setOpen(false)}} center={true} className='bg-transparent' showCloseIcon={true}>
                <div className=' d-flex justify-content-center align-items-center my-3'>
                    <video loop controls autoPlay className=' img-fluid'>
                        <source type='video/mp4' src={vids}/>
                    </video>
                </div>
            </Modal>



            <Modal open={showF} onClose={()=>setShowF(false)} center focusTrapped={false} styles={{root:{"zIndex":"1032"}}} classNames={{modal:" bg-black bg-opacity-25 px-0",closeButton:"bg-warning rounded-circle",root:"bg-black bg-opacity-50"}} >
                <div className=' d-flex justify-content-center align-items-center my-3 rounded overflow-hidden' style={{"maxHeight":"80vh"}}>
                    <img className=' object-fit-contain h-100' alt={img.alt} src={img.img} style={{"maxHeight":"80vh"}} />
                </div>
            </Modal>
        </>
    )
}

export default Home






import React, { useState } from 'react'
import { Tab, Col, Row, Nav } from 'react-bootstrap'


// import Album images;
import im1 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.22.24_IMG_4559_LR.webp"
import im2 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.22.34_IMG_4565_LR.webp"
import im3 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.22.36_IMG_4566_LR.webp"
import im4 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.25.35_0D0A8701_LR.webp"
import im5 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.54.17_0D0A8742_LR.webp"
import im6 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_11.51.20_IMG_4737_LR.webp"
import im7 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_12.22.36_IMG_4962_LR.webp"
import im8 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_12.54.04_IMG_5078_LR.webp"
import im9 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_13.07.14_IMG_5157_LR.webp"
import im10 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_13.10.10_IMG_5167_LR.webp"
import im11 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_13.10.50_IMG_5174_LR.webp"
import im12 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_13.11.13_0D0A9015_LR.webp"
import im13 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.41.58_IMG_5304_LR.webp"
import im14 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.42.00_IMG_5308_LR.webp"
import im15 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.44.34_IMG_5328_LR.webp"
import im16 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.44.36_IMG_5331_LR.webp"
import im17 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.47.58_IMG_5347_LR.webp"
import im18 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.48.02_IMG_5352_LR.webp"
import im19 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.52.34_IMG_5361_LR.webp"
import im20 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.52.36_IMG_5362_LR.webp"
import im21 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.56.52_IMG_5395_LR.webp"
import im22 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_14.57.53_0D0A9180_LR.webp"
import im23 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.17.05_0D0A9234_LR.webp"
import im24 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.23.24_IMG_5490_LR.webp"
import im25 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.23.26_IMG_5492_LR.webp"
import im26 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.47.58_IMG_5565_LR.webp"
import im27 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.49.08_IMG_5576_LR.webp"
import im28 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.49.48_IMG_5579_LR.webp"
import im29 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.49.58_IMG_5582_LR.webp"
import im31 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.50.44_IMG_5590_LR.webp"
import im32 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.51.32_IMG_5606_LR.webp"
import im33 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.52.08_IMG_5615_LR.webp"
import im34 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.52.16_IMG_5619_LR.webp"
import im35 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.52.58_IMG_5632_LR.webp"
import im36 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.54.32_IMG_5650_LR.webp"
import im37 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.54.34_IMG_5651_LR.webp"
import im38 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.54.34_IMG_5652_LR.webp"
import im39 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.54.40_0D0A9323_LR.webp"
import im40 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.54.43_0D0A9328_LR.webp"
import im41 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.55.02_0D0A9334_LR.webp"
import im42 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.55.32_IMG_5662_LR.webp"
import im43 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.56.08_IMG_5673_LR.webp"
import im44 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.56.16_0D0A9366_LR.webp"
import im45 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.57.56_IMG_5696_LR.webp"
import im46 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.58.33_0D0A9410_LR.webp"
import im47 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.03.32_IMG_5736_LR.webp"
import im48 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.06.46_0D0A9472_LR.webp"
import im49 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.06.51_0D0A9475_LR.webp"
import im50 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.08.38_IMG_5758_LR.webp"
import im51 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.09.02_IMG_5768_LR.webp"
import im52 from "../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.09.08_IMG_5771_LR.webp"
import im53 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_00.00.29_0D0A9539_LR.webp"
import im54 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_00.01.02_IMG_5801_LR.webp"
import im55 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_00.34.17_0M2A5945_LR.webp"
import im56 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_00.34.20_0M2A5947_LR.webp"
import im57 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_00.34.23_0M2A5950_LR.webp"
import im58 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_01.32.10_IMG_6050_LR.webp"
import im59 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_01.34.22_IMG_6057_LR.webp"
import im60 from "../../assets/img/EA_RALLYE_ALBUM_1/2 - Soirée_2023-07-02_01.34.36_IMG_6060_LR.webp"
import im61 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6145_LR.webp"
import im62 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6160_LR.webp"
import im63 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6164_LR.webp"
import im64 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6178_LR.webp"
import im65 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6179_LR.webp"
import im66 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6181_LR.webp"
import im67 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6183_LR.webp"
import im68 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6198_LR.webp"
import im69 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6200_LR.webp"
import im70 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6254_LR.webp"
import im71 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6303_LR.webp"
import im72 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6320_LR.webp"
import im73 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6330_LR.webp"
import im74 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6367_LR.webp"
import im75 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6401_LR.webp"
import im76 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6562_LR.webp"
import im77 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6628_LR.webp"
import im78 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6651_LR.webp"
import im79 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6706_LR.webp"
import im80 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6722_LR.webp"
import im81 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6724_LR.webp"
import im82 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6742_LR.webp"
import im83 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6746_LR.webp"
import im84 from "../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6760_LR.webp"



const imgRallye=[
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_10.22.24_IMG_4559_LR.jpg',img: im1},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_10.22.34_IMG_4565_LR.jpg',img: im2},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_10.22.36_IMG_4566_LR.jpg',img: im3},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_10.25.35_0D0A8701_LR.jpg',img: im4},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_10.54.17_0D0A8742_LR.jpg',img: im5},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_11.51.20_IMG_4737_LR.jpg',img: im6},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_12.22.36_IMG_4962_LR.jpg',img: im7},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_12.54.04_IMG_5078_LR.jpg',img: im8},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_13.07.14_IMG_5157_LR.jpg',img: im9},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_13.10.10_IMG_5167_LR.jpg',img: im10},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_13.10.50_IMG_5174_LR.jpg',img: im11},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_13.11.13_0D0A9015_LR.jpg',img: im12},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.41.58_IMG_5304_LR.jpg',img: im13},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.42.00_IMG_5308_LR.jpg',img: im14},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.44.34_IMG_5328_LR.jpg',img: im15},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.44.36_IMG_5331_LR.jpg',img: im16},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.47.58_IMG_5347_LR.jpg',img: im17},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.48.02_IMG_5352_LR.jpg',img: im18},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.52.34_IMG_5361_LR.jpg',img: im19},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.52.36_IMG_5362_LR.jpg',img: im20},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.56.52_IMG_5395_LR.jpg',img: im21},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_14.57.53_0D0A9180_LR.jpg',img: im22},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.17.05_0D0A9234_LR.jpg',img: im23},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.23.24_IMG_5490_LR.jpg',img: im24},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.23.26_IMG_5492_LR.jpg',img: im25},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.47.58_IMG_5565_LR.jpg',img: im26},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.49.08_IMG_5576_LR.jpg',img: im27},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.49.48_IMG_5579_LR.jpg',img: im28},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.49.58_IMG_5582_LR.jpg',img: im29},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.50.44_IMG_5590_LR.jpg',img: im31},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.51.32_IMG_5606_LR.jpg',img: im32},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.52.08_IMG_5615_LR.jpg',img: im33},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.52.16_IMG_5619_LR.jpg',img: im34},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.52.58_IMG_5632_LR.jpg',img: im35},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.54.32_IMG_5650_LR.jpg',img: im36},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.54.34_IMG_5651_LR.jpg',img: im37},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.54.34_IMG_5652_LR.jpg',img: im38},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.54.40_0D0A9323_LR.jpg',img: im39},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.54.43_0D0A9328_LR.jpg',img: im40},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.55.02_0D0A9334_LR.jpg',img: im41},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.55.32_IMG_5662_LR.jpg',img: im42},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.56.08_IMG_5673_LR.jpg',img: im43},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.56.16_0D0A9366_LR.jpg',img: im44},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.57.56_IMG_5696_LR.jpg',img: im45},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_15.58.33_0D0A9410_LR.jpg',img: im46},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_16.03.32_IMG_5736_LR.jpg',img: im47},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_16.06.46_0D0A9472_LR.jpg',img: im48},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_16.06.51_0D0A9475_LR.jpg',img: im49},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_16.08.38_IMG_5758_LR.jpg',img: im50},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_16.09.02_IMG_5768_LR.jpg',img: im51},
    {link:'https://eventify.cm/rallye/1 - Rallye_2023-07-01_16.09.08_IMG_5771_LR.jpg',img: im52},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_00.00.29_0D0A9539_LR.jpg',img: im53},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_00.01.02_IMG_5801_LR.jpg',img: im54},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_00.34.17_0M2A5945_LR.jpg',img: im55},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_00.34.20_0M2A5947_LR.jpg',img: im56},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_00.34.23_0M2A5950_LR.jpg',img: im57},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_01.32.10_IMG_6050_LR.jpg',img: im58},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_01.34.22_IMG_6057_LR.jpg',img: im59},
    {link:'https://eventify.cm/rallye/2 - Soirée_2023-07-02_01.34.36_IMG_6060_LR.jpg',img: im60},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6145_LR.jpg',img: im61},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6160_LR.jpg',img: im62},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6164_LR.jpg',img: im63},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6178_LR.jpg',img: im64},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6179_LR.jpg',img: im65},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6181_LR.jpg',img: im66},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6183_LR.jpg',img: im67},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6198_LR.jpg',img: im68},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6200_LR.jpg',img: im69},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6254_LR.jpg',img: im70},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6303_LR.jpg',img: im71},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6320_LR.jpg',img: im72},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6330_LR.jpg',img: im73},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6367_LR.jpg',img: im74},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6401_LR.jpg',img: im75},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6562_LR.jpg',img: im76},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6628_LR.jpg',img: im77},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6651_LR.jpg',img: im78},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6706_LR.jpg',img: im79},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6722_LR.jpg',img: im80},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6724_LR.jpg',img: im81},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6742_LR.jpg',img: im82},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6746_LR.jpg',img: im83},
    {link:'https://eventify.cm/rallye/3 - Golf_IMG_6760_LR.jpg',img: im84},
]



const Kribi2023 = () => {

    const [img, setImg] = useState(null)
    const [link, setLink] = useState(null)
    const [index, setIndex] = useState(null)

    const getImg = (elt, index) =>{
        setImg(elt)
        setIndex(index)
    }

    const rotateRight = () =>{
        const totalLength = imgRallye.length;
        if (index + 1 >= totalLength) {
            setIndex(0)
            const newUrl = imgRallye[0].img
            setImg(newUrl)
            return;
        }
        setImg(imgRallye[index+ 1].img)
        setLink(imgRallye[index+ 1].link)
        setIndex(index+1)
    }
    
    const rotateLeft = () =>{
        const totalLength = imgRallye.length;
        if (index + 1 <= 1) {
            setIndex(totalLength)
            const newUrl = imgRallye[totalLength-1].img
            setImg(newUrl)
            return;
        }
        setIndex(index-1)
        setLink(imgRallye[index - 1].link)
        setImg(imgRallye[index - 1].img)
    }

    const handleSave = (elt) =>{
        fetch(elt)
        .then(response => response.blob())
        .then(blob => {
            // create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url

            // Extract the filename from URL
            const filename = elt.substring(elt.lastIndexOf('/')+ 1);

            // Set the download attribute and filename
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            // Simulate a click on the anchor element to start the download
            link.click();
        })
    }
    
    return (
        <>
            <section id='bg-tv' className='py-5'>
                <div className='container py-5 pb-2 pb-lg-5 d-flex justify-content-start'>
                    <h1 className='text-center fw-bold pb-0'><span className='aer-color'> RALLYE GT 2023 </span> EN IMAGE </h1>
                </div>
            </section>

            <div className='album mt-5 container'>
                <Tab.Container className = 'text-center mx-auto' id="left-tabs-example" defaultActiveKey={'1'}>
                    <Row className = 'text-center'>
                        <Col sm={11} md={3} className=' position-relative'>
                            <Nav variant="pills" className="d-flex flex-md-column justify-content-center position-sticky top-0 tv-nav mb-4">
                                <Nav.Item >
                                    <Nav.Link className='ms-1' eventKey={'1'}>1</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link className='ms-1' eventKey={'2'}>2</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link className='ms-1' eventKey={'3'}>3</Nav.Link>
                                </Nav.Item>
                                <Nav.Item >
                                    <Nav.Link className='ms-1' eventKey={'4'}>4</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col className = 'mx-auto'>
                            <Tab.Content className = 'mx-auto'>
                                <Tab.Pane eventKey={'1'} >
                                    <div className='gallery'>
                                        {
                                            imgRallye.slice(0,23).map((elt,index)=>{
                                                return(
                                                    <div className="pics position-relative" key={index} >
                                                        <div className='download bg-dark bg-opacity-75 w-100 h-100 position-absolute justify-content-around align-items-center'>
                                                            <div role={'button'} onClick={()=>{getImg(elt.img, imgRallye.indexOf(elt));setLink(elt.img)}} className=' d-flex flex-column'>
                                                                <i className='fa fa-arrows-to-eye fs-2 text-white'></i>
                                                                <small>Voir plus</small>
                                                            </div>
                                                            <div role='button' onClick={()=> handleSave(elt.img)} className=' d-flex flex-column'>
                                                                <i className='fa fa-file-arrow-down fs-1 text-white'></i>
                                                                <small>Télécharger</small>
                                                            </div>
                                                        </div>
                                                        <img src={elt.img} alt={'Event AutoStop Rallye GT'} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'} >
                                    <div className='gallery'>
                                        {
                                            imgRallye.slice(24,46).map((elt,index)=>{
                                                return(
                                                    <div className="pics position-relative" key={index} >
                                                        <div className='download bg-dark bg-opacity-75 w-100 h-100 position-absolute justify-content-around align-items-center'>
                                                            <div role={'button'} onClick={()=>{getImg(elt.img, imgRallye.indexOf(elt));setLink(elt.img)}} className=' d-flex flex-column'>
                                                                <i className='fa fa-arrows-to-eye fs-2 text-white'></i>
                                                                <small>Voir plus</small>
                                                            </div>
                                                            <div role='button' onClick={()=> handleSave(elt.img)} className=' d-flex flex-column'>
                                                                <i className='fa fa-file-arrow-down fs-1 text-white'></i>
                                                                <small>Télécharger</small>
                                                            </div>
                                                        </div>
                                                        <img src={elt.img} alt={'Event AutoStop Rallye GT'} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'3'} >
                                    <div className='gallery'>
                                        {
                                            imgRallye.slice(47,65).map((elt,index)=>{
                                                return(
                                                    <div className="pics position-relative" key={index} >
                                                        <div className='download bg-dark bg-opacity-75 w-100 h-100 position-absolute justify-content-around align-items-center'>
                                                            <div role={'button'} onClick={()=>{getImg(elt.img, imgRallye.indexOf(elt));setLink(elt.img)}} className=' d-flex flex-column'>
                                                                <i className='fa fa-arrows-to-eye fs-2 text-white'></i>
                                                                <small>Voir plus</small>
                                                            </div>
                                                            <div role='button' onClick={()=> handleSave(elt.img)} className=' d-flex flex-column'>
                                                                <i className='fa fa-file-arrow-down fs-1 text-white'></i>
                                                                <small>Télécharger</small>
                                                            </div>
                                                        </div>
                                                        <img src={elt.img} alt={'Event AutoStop Rallye GT'} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'4'} >
                                    <div className='gallery'>
                                        {
                                            imgRallye.slice(66).map((elt,index)=>{
                                                return(
                                                    <div className="pics position-relative" key={index} >
                                                    <div className='download bg-dark bg-opacity-75 w-100 h-100 position-absolute justify-content-around align-items-center'>
                                                        <div role={'button'} onClick={()=>{getImg(elt.img, imgRallye.indexOf(elt));setLink(elt.img)}} className=' d-flex flex-column'>
                                                            <i className='fa fa-arrows-to-eye fs-2 text-white'></i>
                                                            <small>Voir plus</small>
                                                        </div>
                                                        <div role='button' onClick={()=> handleSave(elt.img)} className=' d-flex flex-column'>
                                                            <i className='fa fa-file-arrow-down fs-1 text-white'></i>
                                                            <small>Télécharger</small>
                                                        </div>
                                                    </div>
                                                    <img src={elt.img} alt={'Event AutoStop Rallye GT'} />
                                                </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                {img && <Modal img={img} link={link} rotateRight={rotateRight} rotateLeft={rotateLeft} setImg={setImg} handleSave={handleSave} />}
            </div>
        </>
    )
}

export default Kribi2023




const Modal = (props) =>{

    const handleClick = (e) =>{
        if (e.target.classList.contains("dismiss")) {
            props.setImg(null)
        }
    }

    return(
        <>
            <div className='overlay dismiss' onClick={handleClick} >
                <div onClick={props.rotateRight} className='overlay-arrows__right'>
                    {" "}
                    <i className='ri-arrow-right-circle-fill ri ri-2x text-white'></i>
                </div>
                <div onClick={props.rotateLeft} className='overlay-arrows__left'>
                    {" "}
                    <i className='ri-arrow-left-circle-fill ri ri-2x text-white'></i>
                </div>
                <div className='position-relative mx-auto'>
                    <img src={props.img} alt='EAR 2023' className='img-fluid'/>
                </div>
                <span role={'button'} className='position-absolute' >
                    <i className="ri-2x aer-color ri ri-close-circle-fill dismiss" onClick={()=>props.setImg(null)} ></i>
                    <div role='button' onClick={()=> props.handleSave(props.link)} className=' d-flex flex-column'>
                        <i className='fa fa-cloud-arrow-down fs-3 color-primary'></i>
                        {/* <small>Télécharger</small> */}
                    </div>
                </span>
            </div>
        </>
    )
}

import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import SubmitModal from '../../components/ui/SubmitModal'
import { ClipLoader } from 'react-spinners'

const RegisterParticipant = () => {


    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState('')
    const [genre, setGenre] = useState('Homme')
    const [enterprise, setEnterprise] = useState('')
    const [marque, setMarque] = useState('')
    const [type, setType] = useState('')
    const [plaque, setPlaque] = useState('')
    const [chassis, setChassis] = useState('')
    const [cylindre, setCylindre] = useState('')
    const [division, setDivision] = useState('')
    const [classe, setClasse] = useState('')
    const [accept, setAccept] = useState('')

    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)

    const {register, formState: { errors }, handleSubmit} = useForm();

    const TitlePart = (props) =>{
        return(
            <h3 className='text-uppercase fw-bold text-dark bg-primary-root bg-opacity-75 mt-5 mb-3 p-2'> {props.text} </h3>
        )
    }

    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    const sendEmail = (e) =>{

        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'contact@ear-gt.com',
                From : 'contact@ear-gt.com',
                Subject : "Demande d'inscription - Participant",
                Body :`<strong>PILOTE / CONDUCTEUR</strong>  <br/> Nom: ${name},<br/> Prénom: ${surname},<br/> Numéro de téléphone: ${tel},<br/> Email: ${email},<br/> Sexe: ${genre} <br/><br/> <strong>ENTREPRISE</strong>  <br/> Nom de l'entreprise: ${enterprise}, <br/><br/> <strong>VEHICULE</strong> <br/> Marque: ${marque},<br/> Type: ${type},<br/> Numéro de plaque: ${plaque},<br/> Nombre de Châssis: ${chassis},<br/> Cylindrée: ${cylindre},<br/> Division: ${division},<br/> Classe: ${classe}`
            }).then(message =>{
                console.log(message);
                setRead(false);
                message === 'OK' ? setOk(true): setErr(true)
            })
        }
        console.log(e);
        console.log(chassis);
    }

    const reading = {
        display : "flex",
        margin : "0 auto"
    }


    return (
        <div className='container'>
            <section className='pt-5'>
                <h1 className='text-center pt-5'>Ouverture des inscriptions à l'édition de Février 2024 de Event Auto'Stop Rallye GT</h1>
                <h3 className='mt-5 aer-color'>EVENT & AUTO’STOP MAGAZINE</h3>
                <p>
                    Remplir et soumettre le formulaire ci-dessous jusqu’à 1 mois avant la date du RALLYE GT à 17 heures
                </p>
            </section>
            <form onSubmit={handleSubmit(sendEmail)}>
                <TitlePart text={'PILOTE / CONDUCTEUR'} />
                <div className='row justify-content-start align-items-stretch'>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('name',{required: true})} onChange={(e)=>setName(e.target.value)} value={name} type={'text'} className='w-100' required />
                            <span>Nom</span>
                            <i></i>
                        </div>
                        {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('surname',{required: true})} onChange={(e)=>setSurname(e.target.value)} value={surname} type={'text'} className='w-100' required />
                            <span>Prénom</span>
                            <i></i>
                        </div>
                        {errors.surname && <small className="text-danger" role="alert">Prénom requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('tel',{required: true})} onChange={(e)=>setTel(e.target.value)} value={tel} type='tel' className='w-100' required />
                            <span>Nº Téléphone</span>
                            <i></i>
                        </div>
                        {errors.tel && <small className="text-danger" role="alert">Numéro de téléphone requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('email',{required: true, pattern: emailPattern })} onChange={(e)=>setEmail(e.target.value)} value={email} type={'email'} className='w-100' required />
                            <span>Email</span>
                            <i></i>
                        </div>
                        {errors.email && <small className="text-danger" role="alert">Email requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2 d-flex align-items-start mt-3'>
                        <p className='me-3 py-1'>Sexe:</p>
                        <div className='pe-3 py-1 ps-2 border rounded me-3'>
                            <input type='radio' id='homme' name='sexe' value={'Homme'} onChange={(e)=>setGenre(e.target.value)} defaultChecked className='form-check-input form-check-warning me-2'/>
                            <label for='homme'>Homme</label>
                        </div>
                        <div className='pe-3 py-1 ps-2 border rounded me-3'>
                            <input type='radio' id='femme' name='sexe' value={'femme'} onChange={(e)=>setGenre(e.target.value)} className='form-check-input form-check-warning me-2'/>
                            <label for='femme'>Femme</label>
                        </div>
                    </div>
                </div>
                <TitlePart text={'ENTREPRISE'} />
                <div className='row justify-content-start align-items-stretch'>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('enterprise',{required: true})} onChange={(e)=>setEnterprise(e.target.value)} value={enterprise} type={'text'} readOnly={enterprise === 'pas entreprise'?true:false } className='w-100' required />
                            <span>Nom</span>
                            <i></i>
                        </div>
                        {errors.enterprise && <small className="text-danger" role="alert">Nom de l'entreprise requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2 d-flex align-items-center mt-3'>
                        <input type='checkbox' id='enterprise' value={'pas entreprise'} onChange={(e)=>{enterprise==='pas entreprise'?setEnterprise(''):setEnterprise(e.target.value)}} className='form-check-input form-check-warning me-2'/>
                        <label for='enterprise' className='me-3'>Cochez si pas entreprise</label>
                    </div>
                </div>
                <TitlePart text={'VÉHICULE'} />
                <div className='row justify-content-start align-items-stretch'>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('marque',{required: true})} onChange={(e)=>setMarque(e.target.value)} value={marque} type={'text'} className='w-100' required />
                            <span>Marque</span>
                            <i></i>
                        </div>
                        {errors.marque && <small className="text-danger" role="alert">Marque requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('type',{required: true})} onChange={(e)=>setType(e.target.value)} value={type} type={'text'} className='w-100' required />
                            <span>Type</span>
                            <i></i>
                        </div>
                        {errors.type && <small className="text-danger" role="alert">Type requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('plaque',{required: true})} onChange={(e)=>setPlaque(e.target.value)} value={plaque} type='text' className='w-100' required />
                            <span>Plaque Nº</span>
                            <i></i>
                        </div>
                        {errors.plaque && <small className="text-danger" role="alert">Numéro de plaque requise</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('chassis',{required: true})} onChange={(e)=>setChassis(e.target.value)} value={chassis} type={'number'} className='w-100' required />
                            <span>N° de châssis</span>
                            <i></i>
                        </div>
                        {errors.chassis && <small className="text-danger" role="alert">N° de châssis requis</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('cylindre',{required: true})} onChange={(e)=>setCylindre(e.target.value)} value={cylindre} type={'text'} className='w-100' required />
                            <span>Cylindrée</span>
                            <i></i>
                        </div>
                        {errors.chassis && <small className="text-danger" role="alert">Cylindrée requise</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('division',{required: true})} onChange={(e)=>setDivision(e.target.value)} value={division} type={'text'} className='w-100' required />
                            <span>Division</span>
                            <i></i>
                        </div>
                        {errors.division && <small className="text-danger" role="alert">Division requise</small>}
                    </div>
                    <div className='col-md-6 col-12 mb-2'>
                        <div className='inputBox'>
                            <input {...register('classe',{required: true})} onChange={(e)=>setClasse(e.target.value)} value={classe} type={'text'} className='w-100' required />
                            <span>Classe</span>
                            <i></i>
                        </div>
                        {errors.classe && <small className="text-danger" role="alert">Classe requise</small>}
                    </div>
                    <div className='col-12 mb-2'>
                        <div className='border p-2'>
                            <p className='fw-bold'>Les voitures de production sont réparties en 4 classes de cylindrées</p>
                            <table className=' table table-responsive w-100'>
                                <tr className='border-bottom'>
                                    <td className='w-auto pb-2'>• Classe 1:</td>
                                    <td className='pb-2'>jusqu’à 1 400 cm</td>
                                </tr>
                                <tr className='border-bottom'>
                                    <td className='w-auto pb-2'>• Classe 2:</td>
                                    <td className='pb-2'>au-dessus de 1 400 cm et jusqu’à 1 600 cm</td>
                                </tr>
                                <tr className='border-bottom'>
                                    <td className='w-auto pb-2'>• Classe 3:</td>
                                    <td className='pb-2'>au-dessus de 1 600 cm jusqu’à 2 000 cm</td>
                                </tr>
                                <tr className='border-bottom'>
                                    <td className='w-auto pb-2'>• Classe 4:</td>
                                    <td className='pb-2'>au-dessus de 2000 cm</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className='col-12 my-5 mt-3'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <input type='checkbox' id='accept' value={'accept'} {...register('accept',{required: true})} onChange={(e)=>{accept==='accept'?setAccept(''):setAccept(e.target.value)}} className='form-check-input form-check-warning me-2'/>
                            </div>
                            <label for='accept' className='me-3'>J’autorise EAR à traiter les données reprises ci-dessus, en conformité  avec la déclaration relative à la vie privée, dont j’ai pris connaissance et auxquelles j’adhère .</label>
                        </div>
                        {errors.accept && <small className="text-danger" role="alert">Veuillez autoriser pour s'inscrire</small>}
                    </div>
                </div>

                <div className='mb-5'>
                {
                    read?<button disabled className='btn text-white d-flex bg-transparent border border-2 border-warning'><ClipLoader 
                    color='#fbb03b' 
                    size={30} 
                    cssOverride={reading} 
                    aria-label='Loading spinner' 
                    data-testid='loader' 
                    className='text-center'/> Patientez </button> :
                    ok? <SubmitModal open={ok} setOpen={setOk} message={"Inscription éffectuée avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                    err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                    <input type='submit' value={"S'inscrire"} className='form-control w-auto fw-bold me-3'/>
                }
                </div>
            </form>
        </div>
    )
}

export default RegisterParticipant

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'

// Import slides images;
import im1 from '../../assets/img/EAR_RALLYE_Japoma_2024/13.25.45_B_TEG_3848_LR.jpg';
import im2 from '../../assets/img/EAR_RALLYE_Japoma_2024/17.19.14_C_IMG_0796_LR.jpg';
import im3 from '../../assets/img/EAR_RALLYE_Japoma_2024/17.33.00_A_0M2A0999_LR.jpg';
import im4 from '../../assets/img/EAR_RALLYE_Japoma_2024/17.14.12_A_TEG_4200_LR.jpg';
import im5 from '../../assets/img/EAR_RALLYE_Japoma_2024/16.44.20_A_TEG_4028_LR.jpg';
import im6 from '../../assets/img/EAR_RALLYE_Japoma_2024/14.13.04_C_IMG_0570_LR.jpg';
import im7 from '../../assets/img/EAR_RALLYE_Japoma_2024/11.33.34_E_IMG_9742_LR.jpg';
import im8 from '../../assets/img/EAR_RALLYE_Japoma_2024/09.49.44_A_0M2A9010_LR.jpg';


// Import photo of presentation - pilotes

import jingI from '../../assets/img/partner/PHOTO_PILOTE_2024/paul_jing.webp'
import tinenI from '../../assets/img/partner/PHOTO_PILOTE_2024/tinen.jpg'
import ndoumbeI from '../../assets/img/partner/PHOTO_PILOTE_2024/ndoumbe.jpg'
import scobyI from '../../assets/img/partner/PHOTO_PILOTE_2024/SCOBY.jpg'
import nyodogI from '../../assets/img/partner/PHOTO_PILOTE_2024/PERRIAL.jpg'
import tchoptchopI from '../../assets/img/partner/PHOTO_PILOTE_2024/TCHOP_TCHOP.jpg'
import tembiwaI from '../../assets/img/partner/PHOTO_PILOTE_2024/xavier.png'
import canalI from '../../assets/img/partner/PHOTO_PILOTE_2024/canalplus.png'
import petridisI from '../../assets/img/partner/PHOTO_PILOTE_2024/patrocle.png'
import mbakopI from '../../assets/img/partner/PHOTO_PILOTE_2024/caroline.png'
import tchatoI from '../../assets/img/partner/PHOTO_PILOTE_2024/AORNELA_TCHATO.png'
import anthonysameI from '../../assets/img/partner/PHOTO_PILOTE_2024/anthony_same.png'
import epeeI from '../../assets/img/partner/PHOTO_PILOTE_2024/junior_epee.png'
import tchamakoI from '../../assets/img/partner/PHOTO_PILOTE_2024/joel_tchamako.png'
import songI from '../../assets/img/partner/PHOTO_PILOTE_2024/alex_song.jpg'
import patrickI from '../../assets/img/partner/PHOTO_PILOTE_2024/patrick.webp'
import kontchouI from '../../assets/img/partner/PHOTO_PILOTE_2024/stephane_nkontchou.jpg'
import yoleneI from '../../assets/img/partner/PHOTO_PILOTE_2024/yolene_ngounou.jpg'
import asakoI from '../../assets/img/partner/PHOTO_PILOTE_2024/achile.png'
import pierreguillaumeI from '../../assets/img/partner/PHOTO_PILOTE_2024/pierre_guillaume.jpg'
import gbekanI from '../../assets/img/partner/PHOTO_PILOTE_2024/mathieu_gbekan.jpg'


// Import video of presentation - pilotes

import canalV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_CANAL+_2024.mp4'
import songV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_ALEXANDRE_SONG_2024.mp4'
import gbekanV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_MATHIEUR_GBEKAN_2024.mp4'
import pierreguillaumeV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_PIERRE_GUILLAUME_2024.mp4'
import tchoptchopV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_P.D.T_TCHOP_TCHOP_2024.mp4'
import yoleneV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_YOLENE_2024.mp4'
import tembiwaV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_XAVIER_TEMBIWA_2024.mp4'
import nyodogV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_PERRIAL_NYODOG_2024.mp4'
import petridisV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_PATROCLE_PETRIDIS_2024.mp4'
import anthonysameV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_ANTHONY_SAME_2024.mp4'
import mbakopV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_CAROLINE_MBAKOP_2024.mp4'
import tinenV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_HILAIRE_TINEN_2024.mp4'
import scobyV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_SCOBY_AUGUSTAIN_2024.mp4'
import tchatoV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_ORNELA_TCHATO_2024.mp4'
import patrickV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_PATRICK_LESBOIS_2024.mp4'
import jingV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_PAUL_JING_2024.mp4'
import kontchouV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_STEPHANE_NKONTCHOU_2024.mp4'
import tchamakoV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_JOEL_TCHAMAKO_2024.mp4'
import epeeV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_JUNIOR_EPEE_2024.mp4'
import ndoumbeV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_GUY_NDOUMBE_2024.mp4'
import asakoV from '../../assets/video/partner/EAR_PRESENTATION_PILOTE_2024/EAR_PRES_ACHILE_ASAKO_2024.mp4'


// Import news images
import horizon_camer from '../../assets/img/news/japoma2024/horizon_camer.jpeg'
import camer_be from '../../assets/img/news/japoma2024/camer_be.jpg'
import quatrieme_pouvoir from '../../assets/img/news/japoma2024/quatrieme_pouvoir.png'
import cameroon_file from '../../assets/img/news/japoma2024/cameroon_file.jpg'



import Title from '../../components/ui/Title';
import Modal from 'react-responsive-modal';
import Carousel from 'react-multi-carousel';
import Timer from '../../components/ui/Timer';
import { Link, NavLink, useLocation } from 'react-router-dom';



const NewsCard = (props) => {
    return (
        <div className='col-lg-4 col-md-6 col-12 mb-4 align-items-stretch'>
            <div className='rounded-3 mb-3 card shadow h-100 position-relative bg-black text-white border '>
                <div className=' d-flex justify-content-center align-items-center' style={{"height":"13rem"}}>
                    <img alt={props.title} src={props.img}  className='rounded-3 object-fit-cover w-100 h-100' />
                </div>
                <div className=' card-body'>
                    <h5 className='aer-color text-center text-uppercase'> {props.title} </h5>
                    <p className=''> {props.description} </p>
                    <small className='fs-6 fw-thin text-uppercase'> {props.date} </small>
                    <br></br>
                </div>
                <div className=' card-footer border-0 mx-auto mb-2 '>
                    <a rel='noreferrer' target='_blank' href={props.link} className='color-primary text-decoration-none border border-1 border-warning rounded-pill py-1 px-3 fs-5'> Lire plus... </a>
                </div>
            </div>
        </div>
    )
}



const EarJapoma2024 = () => {

    const [open, setOpen] = useState(false)
    const [vids, setVids] = useState(false)

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
        window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ block: "start", inline: "start" });
            }
        }, 0);
        }
    }, [pathname, hash, key]); 

    const links = [
        {icon: "fa-regular fa-folder-open", title: "Album photo", path:"/japoma/album_photo"},
        {icon: "fa-regular fa-newspaper", title: "Revue de presse", path:"https://cameroon-files.com/ear-rallye-gt-paul-jing-simpose-une-fois-de-plus/?noamp=mobile"},
        {icon: "fa fa-film", title: "vidéo", path:"https://youtu.be/nrCj7i2f_sw?si=3OaVnYYVTB9UWvlp"}
    ]

    const imgRallye=[
        {img: im1,},
        {img: im2,},
        {img: im3,},
        {img: im4,},
        {img: im5,},
        {img: im6,},
        {img: im7,},
        {img: im8,},
    ]

    const responsiveVids = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet:{
            breakpoint: { max: 1200, min: 767 },
            items: 1
        },
        mobilet:{
            breakpoint: { max: 972, min: 380 },
            items: 1
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 1
        },
    }

    const person = [
        {imag:epeeI , nom:'Junior Epee', role:"DG de SHOOTING STAR", vid:epeeV},
        {imag:tchamakoI , nom:'Joel Tchamako', role:"PDG de GAP MOTORS", vid:tchamakoV},
        {imag:canalI , nom:'Canal +', role:"Chaine de télé", vid:canalV},
        {imag:mbakopI , nom:'Caroline Mbakop', role:"Promotrice du restaurant les 2Rives", vid:mbakopV},
        {imag:kontchouI , nom:'Stephane Kontchou', role:"CEO & Founder Ecofood Services", vid:kontchouV},
        // {imag:petridisI , nom:'Patrocle Petridis', role:"DG Total Energy Cameroun", vid:petridisV},
        // {imag:tinenI , nom:'hilaire tinen', role:"PDG CAMTRACK", vid:tinenV},
        // {imag:nyodogI , nom:'Perrial Nyodog', role:"Président de GULFCAM", vid:nyodogV},
        // {imag:asakoI , nom:'Achille Asako', role:"Manager du groupe Cerise", vid:asakoV},
        // {imag:anthonysameI , nom:'Anthony Same', role:"PDG ST Digital", vid:anthonysameV},
        // {imag:tchatoI , nom:'Ornela Tchato', role:"Conseillère Municipale - Mairie de BAZOU", vid:tchatoV},
        // {imag:ndoumbeI , nom:'Guy Ndoumbe', role:"Directeur général H-CODE", vid:ndoumbeV},
        {imag:jingI , nom:'Paul Jing', role:"Avocat d'affaire", vid:jingV},
        {imag:tembiwaI , nom:'Xavier Tembiwa', role:"PDG AFRIGAZ", vid:tembiwaV},
        {imag:tchoptchopI , nom:'PDT Tchop-Tchop', role:"PDG JAMBO MEDIA", vid:tchoptchopV},
        // {imag:scobyI , nom:'Auguste Scoby', role:"Pilote de ligne", vid:scobyV},
        // {imag:songI , nom:'Alexandre Song', role:"Ancien Joueur International Camerounais", vid:songV},
        // {imag:gbekanI , nom:'Mathieu Gbekan', role:"Directeur South Investment Partners", vid:gbekanV},
        // {imag:pierreguillaumeI , nom:'pierre guillaume ', role:"Représentant résident CEMAC au Cameroun", vid:pierreguillaumeV},
        // {imag:yoleneI , nom:'Yolene Ngounou', role:"Manager chez AGL", vid:yoleneV},
        // {imag:patrickI , nom:'Patrick Lesbois', role:"Directeur Géneral Chrono Service", vid:patrickV},
    ]

    const news =[
        {image:quatrieme_pouvoir, title:"Le grand retour de EAR Rallye GT à Douala", date:"6 Février 2024", description:"Sous l'impulsion dynamique de Miss Mahop, l'agence Mahoption Ltd et le magazine Auto-stop unissent leurs talents pour célébrer l'automobile à travers cet événement mêlant performance automobile, élégance et engagement citoyen.", link:"https://lequatriemepouvoir.com/le-grand-retour-de-ear-rallye-gt-a-douala/"},
        {image:horizon_camer, title:"Le retour triomphant de l’EAR Rallye GT à Douala-Japoma", date:"6 Février 2024", description:"L’agence événementielle et de communication Mahoption Ltd, en collaboration avec le magazine Auto-stop, annonce avec enthousiasme le retour tant attendu de l’EAR Rallye GT à Douala-Japoma.", link:"https://horizoncamer.com/2024/02/06/sport-le-retour-triomphant-de-lear-rallye-gt-a-douala-japoma/"},
        {image:camer_be, title:"LE RALLYE EAR GT DÉBARQUE À DOUALA ", date:"6 Février 2024", description:"Le come-back de l'EAR Rallye GT à Douala du 24 au 25 février est une annonce qui réjouit à la fois l'agence Mahoption Ltd et le magazine Auto-stop. Après avoir connu un franc succès lors de sa première édition à Kribi l'an dernier, cette compétition de référence dans le milieu promet encore plus de sensations fortes.", link:"https://www.camer.be/84320/7:1/cameroun-sport-le-rallye-ear-gt-debarque-a-douala-cameroon.html"},
        {image:cameroon_file, title:"Après Kribi, l’Ear Gt débarque à Douala", date:"7 Février 2024", description:"Après Kribi en juillet 2023, Eventify auto stop rallye Gt (Ear) débarque à Douala, les 24 et 25 février 2024. L’annonce a été faite mardi, 6 février par le comité d’organisation de l’événement, au cours d’un conférence de presse.", link:"https://cameroon-files.com/sports-automobiles-apres-kribi-lear-gt-debarque-a-douala/"},
    ]

    const responsive = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet:{
            breakpoint: { max: 1200, min: 767 },
            items: 3
        },
        mobilet:{
            breakpoint: { max: 992, min: 380 },
            items: 2
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 1
        },
    }

    return (
        <>
            <section>
                <Carousel responsive={responsiveVids} infinite={true} autoPlay={true} autoPlaySpeed={2000} >
                {
                    imgRallye.map((elt, index)=>{
                        return(
                            <div key={index} className='hero-slide'>
                                <div className=' position-relative d-flex justify-content-center'>
                                    <div className=' position-absolute w-100 h-100 bg-dark bg-opacity-25'></div>
                                    <img alt='Event AutoStop Rallye GT 2023' src={elt.img} className='img-fluid img' />
                                </div>
                            </div>
                        )
                    })
                }
                </Carousel>
            </section>

            <div className='container'>
                <div id='japoma-pilotes'>
                    <Title text={"Présentation des pilotes edition Douala - japoma 2024"} />
                    <Carousel responsive={responsive} showDots={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='container bg-transparent' >
                            {
                                person.map((elt,index)=>(
                                    <div key={index} className='px-2 mb-4 position-relative h-100'>
                                        <div className='rounded-3 mb-3 border border-1 border-light bg-transparent card shadow-sm'>
                                            <div className='team-img'>
                                                <div className='aer-img-team-mobile overflow-hidden'>
                                                    <img src={elt.imag} alt={elt.nom+ " " + elt.role} className='rounded-circle' />
                                                </div>
                                            </div>
                                            <div className='px-1 gx-0 card-body text-center team-card mb-5'>
                                                <h5 className='text-light text-uppercase'>{elt.nom}</h5>
                                                <span className='color-primary text-center'>{elt.role}</span>
                                                <br/>
                                                <i role='button' className='fa-brands fa-youtube text-light fa-2x mb-sm-3 mb-1 btn-vid bg-transparent ' onClick={()=>{setOpen(true); setVids(elt.vid)}}></i>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                    </Carousel>
                </div>

                
                <div className='mx-auto row justify-content-center'>
                    <Title text={"Encore plus sur l'édition Japoma 2024"} />
                    {
                        links.map((elt, index) =>(
                            <div className='col-xs-6 col mb-2 my-2 col-md-4' key={index}>
                                <Link to={elt.path} className='text-decoration-none' >
                                    <div className='adress-card bg-white shadow rounded border border-2 border-white p-2 text-dark text-center'>
                                        <div className=' w-auto mb-3'>
                                            <i className={ elt.icon + " gradient-back fs-1 mx-3 border border-2 py-3 rounded-circle py-4 px-4"}></i>
                                        </div>
                                        <small className='fw-bold text-uppercase text-dark'>{elt.title}</small>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>


                <div className='mt-5 pt-3'>
                    {/* <iframe className='fp-iframe' allowFullScreen="allowfullscreen" scrolling="no" src="https://heyzine.com/flip-book/219c03b270.html#page/2" width="100%" height="800px" title="Event Auto'Stop Rallye GT" ></iframe> */}
                    <iframe className='fb-iframe' src="https://online.fliphtml5.com/ssivn/pdvt/" width="100%" height="800px"  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowFullScreen="true" title="Event Auto'Stop Rallye GT"></iframe>
                </div>


            </div>



            <Modal open={open} onClose={()=>{setOpen(false)}} center={true} className='bg-transparent' showCloseIcon={true}>
                <div className=' d-flex justify-content-center align-items-center my-3'>
                    <video loop controls autoPlay className=' img-fluid'>
                        <source type='video/mp4' src={vids}/>
                    </video>
                </div>
            </Modal>
        </>
    )
}

export default EarJapoma2024

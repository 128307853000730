/* eslint-disable eqeqeq */
import React, { useContext } from 'react'
import { AppContexte } from '../context/context'
import { NavLink, useParams } from 'react-router-dom';
import Title from '../components/ui/Title';
import SearchBar from '../components/ui/SearchBar';
import MetaDecorator from '../components/ui/MetaDecorator';
import { URL_API } from '../constant/url';
import { FacebookShareButton} from 'react-share'


const News = () => {


    const { actu, categorie } = useContext(AppContexte)

    let { catId, actuId } = useParams();

    const recent = actu.slice(actu.length - 3).reverse()

    const part = categorie.filter(elt => elt.id == catId )
    

    return (
        <div className='container pt-5 ql-editor'>

            <div className='position-fixed search-news'>
                <SearchBar/>
            </div>


            <div id='actu-header' className='my-5 pt-3'>
                <Title text={"Retrouvez l'ensemble des actualités de EAR - " + part[0].label }/>
            </div>

            <div id='actu-datas'>
                <div className='row'>
                    {
                        // Detail d'un article
                        actuId !== "all" ?
                        actu.map((act,index)=>(
                            act.id == actuId?(
                                <MetaDecorator
                                    description={act.subtitle}
                                    title={act.title}
                                    imageUrl={`${URL_API}${act.image}`}
                                />,
                                <div className='bg-black container py-3 text-white' key={index} >
                                    <h2 className='text-uppercase text-center'> {act.title} </h2>
                                    <div className='text-center position-relative w-100'>
                                        <img src={`${URL_API}${act.image}`} alt={act.title} className='d-lg-block d-none mx-auto w-auto img-fluid' style={{"maxHeight":"75vh"}} />
                                        <img src={`${URL_API}${act.image}`} alt={act.title} className='d-lg-none mx-auto w-auto img-fluid' style={{"maxHeight":"75vh"}} />
                                        <div className='position-absolute top-0 end-0'>
                                            <div className='bg-white bg-opacity-50 px-3 py-2'>
                                                <span className='text-decoration-underline fst-italic fa-1x fw-bold'>Partager sur :</span>
                                                <div className='about-text social-logo row justify-content-around mt-3'>
                                                    <div className='bg-linkedin mx-1 col-auto rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='linkedin'>
                                                        <FacebookShareButton url={`https://ear-gt.com${window.location.pathname}${window.location.search}`} quote={'Rejoignez-nous !!!'}>
                                                            <i className='eventify-social fa-brands text-white fa-linkedin-in'></i>
                                                        </FacebookShareButton>
                                                    </div>
                                                    <div className='bg-facebook mx-1 col-auto rounded-circle mb-3 py-md-2 py-1 shadow d-flex justify-content-center align-items-center' title='facebook'>
                                                        <FacebookShareButton url={`https://ear-gt.com${window.location.pathname}${window.location.search}`} quote={'Rejoignez-nous !!!'}>
                                                            <i className='eventify-social fa-brands text-white fa-facebook-f'></i>
                                                        </FacebookShareButton>
                                                    </div>
                                                </div>
                                            </div>
                                            <FacebookShareButton url={`https://ear-gt.com${window.location.pathname}${window.location.search}`} quote={'Rejoignez-nous !!!'}>
                                            </FacebookShareButton>
                                        </div>
                                    </div>
                                    <h4 className='color-primary text-start my-4'> {act.subtitle} </h4>
                                    <div dangerouslySetInnerHTML={{ __html: act.text}}></div>
                                </div>

                            ):null
                        ))
                        :
                        // Liste des articles
                        actu.map((act, index) =>(
                            act.categorie_id == catId ?
                            <div className='col-md-6 col-12' key={index}>
                                <div className='py-3 mb-4'>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={"/actualite/" + act.categorie_id + "/" + act.id} className='p-0 position-relative'>
                                        <div className='w-100 overflow-hidden actu-img'>
                                            <img src={`${URL_API}${act.image}`} alt={act.title} className='w-100'/>
                                        </div>
                                        <div className='bg-actu position-absolute p-2 bg-black bg-opacity-75'>
                                            <h5 className='text-white m-0 text-uppercase fw-medium'>{act.title}</h5>
                                            <small className='color-primary'>{act.subtitle} </small>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                            :null
                        ))
                    }
                </div>
            </div>

            <div id='actu-recent' className='mt-3 mb-2'>
                <Title text="Articles récents" />
                <div className='mt-3 row'>
                    {
                        recent.map((act, index) =>(
                            <div className='col-md-4 col-12' key={index}>
                                <div className='py-3 mb-4'>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={"/actualite/" + act.categorie_id + "/" + act.id} className='p-0 position-relative'>
                                        <div className='w-100 overflow-hidden actu-img'>
                                            <img src={`${URL_API}${act.image}`} alt={act.title} className='w-100'/>
                                        </div>
                                        <div className='bg-actu position-absolute p-2 bg-black bg-opacity-75'>
                                            <h6 className='text-white m-0 text-uppercase fw-medium'>{act.title}</h6>
                                            <small className='color-primary'>{act.subtitle} </small>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div id='actu-best' className='mt-3 mb-2'>
                <Title text="articles à la une" />
                <div className='mt-3 row'>
                    {
                        categorie.map((cat) =>(
                            actu.map((act, index) =>(
                                (act.id == cat.best ) && ( cat.id != catId )?
                                <div className='col-md-4 col-12' key={index}>
                                    <div className='py-3 mb-4'>
                                        <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={"/actualite/" + act.categorie_id + "/" + act.id} className='p-0 position-relative'>
                                            <div className='w-100 overflow-hidden actu-img'>
                                                <img src={`${URL_API}${act.image}`} alt={act.title} className='w-100'/>
                                            </div>
                                            <div className='bg-actu position-absolute p-2 bg-black bg-opacity-75'>
                                                <h6 className='text-white m-0 text-uppercase fw-medium'>{act.title}</h6>
                                                <small className='color-primary'>{act.subtitle} </small>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                                :null
                            ))
                        ))
                    }
                </div>
            </div>


        </div>
    )
}

export default News
import React from 'react'
import imgSoon from '../assets/img/soon1.jpg'

const Soon = () => {
    return (
        <div className=' d-flex justify-content-center align-items-center' style={{"minHeight":"80vh"}}>
            <div className=' container mt-4'>
                <img alt="Comming soon" className=' img-fluid' src={imgSoon} />
            </div>
        </div>
    )
}

export default Soon
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { URL_API_ACTU, URL_API_CATEGORIE, URL_API_SPONSORS } from '../constant/url'

export const AppContexte = React.createContext()


export const GlobalApp  = ({children}) =>{

    const[categorie, setCategorie]= useState([])
    const[actu, setActu]= useState([])
    const[part, setPart] = useState('')
    const[title, setTitle] = useState('')
    const[subtitle, setSubtitle] = useState('')
    const[text, setText] = useState('')
    const[image, setImage] = useState()

    const[sponsor, setSponsor] = useState()
    const[loadSponsor, setLoadSponsor] = useState()
    const[errorSponsor, setErrorSponsor] = useState()

    //Loading error
    const [loadingErr, setLoadingErr] = useState(false)

    // Loading
    const [load, setLoad] = useState(false)


    useEffect(()=>{
        axios
        .get(URL_API_CATEGORIE)
        .then((response)=>{
            setCategorie(response.data)
            axios
            .get(URL_API_ACTU)
            .then((response)=>{
                setActu(response.data)
                setTimeout(()=>{
                    setLoad(true)
                },300)
            })
            .catch((err) => {console.log(err.message);setLoad(true); setLoadingErr(true)})
        })
        .catch((err) => {console.log(err.message);setLoad(true); setLoadingErr(true)})
    },[])

    useEffect(()=>{
        setLoadSponsor(true)
        axios
        .get(URL_API_SPONSORS)
        .then(res => {setLoadSponsor(false); setErrorSponsor(false); console.log(res); setSponsor(res.data.data)})
        .catch(err => {setLoadSponsor(false); console.log(err); setErrorSponsor(true)})
    },[])


    let update = {
        categorie : categorie,
        setCategorie : setCategorie,
        actu : actu,
        setActu : setActu,
        part : part,
        setPart : setPart,
        title : title,
        setTitle : setTitle,
        subtitle : subtitle,
        setSubtitle : setSubtitle,
        text : text,
        setText : setText,
        image : image,
        setImage : setImage,

        //loading error
        loadingErr: loadingErr,
        setLoadingErr: setLoadingErr,

        //loading
        load: load,
        setLoad: setLoad,

        //sponsors
        loadSponsor: loadSponsor,
        setLoadSponsor: setLoadSponsor,
        sponsor: sponsor,
        setSponsor: setSponsor,
        errorSponsor: errorSponsor,
        setErrorSponsor: setErrorSponsor,
    }

    return(
        <AppContexte.Provider value={update}>
            {children}
        </AppContexte.Provider>
    )
}
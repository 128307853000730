import React, { useEffect, useState } from 'react'

const Timer = () => {

    const now = new Date().getTime()
    const countDownDate =  new Date('November 29, 2024').getTime()
    const distance = countDownDate - now;
    
    
    const [days, setDays] = useState(Math.floor(distance / (1000 * 60 * 60 *24)))
    const [hours, setHours] = useState(Math.floor((distance % (1000 * 60 * 60 *24)) / (1000 * 60 *60)))
    const [minutes, setMinute] = useState(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
    const [seconds, setSeconds] = useState(Math.floor((distance % (1000 * 60)) / 1000))

    
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime()
            const countDownDate =  new Date('November 29, 2024').getTime()
            const distance = countDownDate - now;

            setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
        }, 1000);
        return () => clearInterval(interval);
    }, [distance]);


    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime()
            const countDownDate =  new Date('November 29, 2024').getTime()
            const distance = countDownDate - now;

            setMinute(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        }, 1000);
        return () => clearInterval(interval);
    }, [distance,]);


    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime()
            const countDownDate =  new Date('November 29, 2024').getTime()
            const distance = countDownDate - now;

            setHours(Math.floor((distance % (1000 * 60 * 60 *24)) / (1000 * 60 * 60)));
        }, 1000);
        return () => clearInterval(interval);
    }, [distance,]);


    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime()
            const countDownDate =  new Date('November 29, 2024').getTime()
            const distance = countDownDate - now;

            setDays(Math.floor(distance / (1000 * 60 * 60 *24)));
        }, 1000);
        return () => clearInterval(interval);
    }, [distance,]);


    return (
        <div className='row justify-content-around text-center rounded px-2  py-md-1 py-0 text-black'>
            <div className='col-3 row gx-0 flex-column justify-content-center align-items-center mt-md-0 mt-2'>
                <div className='px-1'>
                    <div className='bg-black bg-opacity-75'>
                        <span className='text-white fw-bold m-0'>{days}</span>
                        <p className='color-primary bg-black py-1 text-uppercase mb-0'>Jours</p>
                    </div>
                </div>
            </div>
            <div className='col-3 row gx-0 flex-column justify-content-center align-items-center mt-md-0 mt-2'>
                <div className='px-1'>
                    <div className='bg-black bg-opacity-75'>
                        <span className='text-white fw-bold m-0'>{hours}</span>
                        <p className='color-primary bg-black py-1 text-uppercase mb-0'>Heures</p>
                    </div>
                </div>
            </div>
            <div className='col-3 row gx-0 flex-column justify-content-center align-items-center mt-md-0 mt-2'>
                <div className='px-1'>
                    <div className='bg-black bg-opacity-75'>
                        <span className='text-white fw-bold m-0'>{minutes}</span>
                        <p className='color-primary bg-black py-1 text-uppercase mb-0'>Minutes</p>
                    </div>
                </div>
            </div>
            <div className='col-3 row gx-0 flex-column justify-content-center align-items-center mt-md-0 mt-2'>
                <div className='px-1'>
                    <div className='bg-black bg-opacity-75'>
                        <span className='text-white fw-bold m-0'>{seconds}</span>
                        <p className='color-primary bg-black py-1 text-uppercase mb-0'>Secondes</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Timer

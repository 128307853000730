import React from 'react'

const Title = (props) => {
    return (
        <div className='title mt-md-5 mt-3 py-md-5 py-3'>
            <h2 className='text-light fw-bold text-uppercase'>{props.text}</h2>
        </div>
    )
}

export default Title

import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import SubmitModal from '../../components/ui/SubmitModal'
import { ClipLoader } from 'react-spinners'



const RegisterAdvertiser = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)

    const {register,formState: { errors },handleSubmit} = useForm();

    const form = useRef();

    const sendEmail = async(e) =>{

        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'contact@ear-gt.com',
                From : 'contact@ear-gt.com',
                Subject : "Demande d'inscription - Annonceur",
                Body :`<div>
                <h1 style="background: black; color: #fff"> <strong> Demande d'inscription: ANNONCEUR EAR - GT </strong> </h1>
                <h2 style="padding-left: 1rem; color: #eab70f; margin-bottom: 0px;"> <strong> INFOS CLIENT </strong> </h2>
                <h4 style="padding-left: 1.5rem"><strong style="color: #eab70f; margin-bottom: 0px;">Nom: </strong> ${name}</h4>
                <h4 style="padding-left: 1.5rem"><strong style="color: #eab70f; margin-bottom: 0px;">Email: </strong> ${email}</h4>
                <h2 style="padding-left: 1rem; color: #eab70f; margin-top: 2rem; margin-bottom: 0px;"> <strong> MESSAGE </strong> </h2>
                <div style="padding-left: 1rem">${message}</div>
            </div>`
            }).then((message) =>{
                return(
                    console.log(message),
                    setRead(false),
                    message === 'OK' ?( setOk(true), setName(''), setEmail(''), setMessage('') ): setErr(true)
                )
            })
        }
        
    
        console.log(e);
    }

    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    const reading = {
        display : "flex",
        margin : "0 auto"
    }

    return (
        <div className='container pt-5'>
            <div className='pt-5'>
                <h1 className='mb-3 mt-md-5 mt-0 pt-md-5 pt-3 text-center'>Soumettre votre demande d'<span className='aer-color'>annonceur </span> </h1>
                <form ref={form} onSubmit={handleSubmit(sendEmail)}>
                    <div className='row justify-content-start align-items-strech'>
                        <div className='col-md-6 col-12 mb-2'>
                            <div className='inputBox'>
                                <input {...register("name", {required: true })} onChange={(e)=>(setName(e.target.value))} value={name} type='text' className='w-100' required/>
                                <span>Nom</span>
                                <i></i>
                            </div>
                            {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                        </div>
                        <div className='col-md-6 col-12 mb-2'>
                            <div className='inputBox'>
                                <input {...register("email", {required: true, pattern: emailPattern })} onChange={(e)=>(setEmail(e.target.value))} value={email} type='text' className='w-100' required/>
                                <span>Email</span>
                                <i></i>
                            </div>
                            {errors.email?.type === "required" && <small className="text-danger" role="alert">Email requis</small>}
                            {errors.email?.type === "pattern" && <small className="text-danger" role="alert">Syntaxe d'email incorrecte</small>}
                        </div>
                        <div className='col-12 my-3'>
                            <label for="msg" className='pb-1 ps-2'>Message</label>
                            <textarea {...register("message", {required: true })} id={'msg'} onChange={(e)=>(setMessage(e.target.value))} value={message} className='form-control bg-transparent text-white shadow-none' rows={'10'}></textarea>
                            {errors.message?.type === "required" && <small className="text-danger" role="alert">Message requis</small>}
                        </div>
                        <div className='d-flex justify-content-center col-12 mb-3'>
                            {
                                read?<button className='btn text-white bg-transparent border border-2 border-warning'><ClipLoader 
                                color='#fbb03b' 
                                size={30} 
                                cssOverride={reading} 
                                aria-label='Loading spinner' 
                                data-testid='loader' 
                                className='text-center'/>Patientez</button> :
                                ok? <SubmitModal open={ok} setOpen={setOk} message={"Message envoyé avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                                err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                                <input type='submit' value={'Soumettre'} className='form-control w-100 me-3'/>
                            }
                            <input onClick={()=>{setName('');setEmail('');setMessage('')}} type='reset' className='form-control w-100 me-3'/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterAdvertiser
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Tab, Col, Row, Nav } from 'react-bootstrap'
import Title from '../../components/ui/Title';



// import images for album
import i01 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.25.06_A_0M2A0525_LR.jpg";
import i02 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.25.32_A_0M2A0528_LR.jpg";
import i03 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.25.18_A_0M2A0527_LR.jpg";
import i04 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.24.54_A_0M2A0519_LR.jpg";
import i05 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.25.00_C_IMG_0655_LR.jpg";
import i06 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.24.58_C_IMG_0654_LR.jpg";
import i07 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.24.26_A_0M2A0511_LR.jpg";
import i08 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.24.16_A_0M2A0509_LR.jpg";
import i09 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.24.40_A_0M2A0515_LR.jpg";
import i10 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.24.04_A_0M2A0505_LR.jpg";
import i11 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.23.00_A_0M2A0497_LR.jpg";
import i12 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.23.08_C_IMG_0649_LR.jpg";
import i13 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.23.04_C_IMG_0648_LR.jpg";
import i14 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.22.54_A_0M2A0495_LR.jpg";
import i15 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.22.26_A_0M2A0487_LR.jpg";
import i16 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.22.34_A_0M2A0488_LR.jpg";
import i17 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.22.06_A_0M2A0483_LR.jpg";
import i18 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.21.56_A_0M2A0481_LR.jpg";
import i19 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.21.02_C_IMG_0642_LR.jpg";
import i20 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.21.08_C_IMG_0643_LR.jpg";
import i21 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.28.12_A_0M2A9936_LR.jpg";
import i22 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.30.26_A_0M2A9943_LR.jpg";
import i23 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.27.58_A_0M2A9928_LR.jpg";
import i24 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.28.02_A_0M2A9930_LR.jpg";
import i25 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.27.50_A_0M2A9924_LR.jpg";
import i26 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.27.00_A_0M2A9911_LR.jpg";
import i27 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.58_A_0M2A9908_LR.jpg";
import i28 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.54_A_0M2A9906_LR.jpg";
import i29 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.36_A_0M2A9900_LR.jpg";
import i30 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.40_A_0M2A9903_LR.jpg";
import i31 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.34_A_0M2A9897_LR.jpg";
import i32 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.28_A_0M2A9895_LR.jpg";
import i33 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.24_A_0M2A9893_LR.jpg";
import i34 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.18_A_0M2A9890_LR.jpg";
import i35 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.26.04_A_0M2A9888_LR.jpg";
import i36 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.25.40_A_0M2A9881_LR.jpg";
import i37 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.25.44_A_0M2A9883_LR.jpg";
import i38 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.25.45_B_TEG_3848_LR.jpg";
import i39 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.25.14_A_0M2A9877_LR.jpg";
import i40 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.23.44_A_0M2A9854_LR.jpg";
import i41 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.23.30_A_0M2A9851_LR.jpg";
import i42 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.23.00_A_0M2A9848_LR.jpg";
import i43 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.22.58_A_0M2A9846_LR.jpg";
import i44 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.22.16_A_0M2A9839_LR.jpg";
import i45 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.54.12_A_0M2A9361_LR.jpg";
import i46 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.46.56_A_0M2A9215_LR.jpg";
import i47 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.44.48_A_0M2A9191_LR.jpg";
import i48 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.33.00_A_0M2A0999_LR.jpg";
import i49 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.29.40_C_IMG_0878_LR.jpg";
import i50 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.29.38_C_IMG_0877_LR.jpg";
import i51 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.27.24_A_0M2A0986_LR.jpg";
import i52 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.28.14_C_IMG_0872_LR.jpg";
import i53 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.26.20_A_0M2A0973_LR.jpg";
import i54 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.26.30_A_0M2A0977_LR.jpg";
import i55 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.26.20_C_IMG_0869_LR.jpg";
import i56 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.26.02_A_0M2A0962_LR.jpg";
import i57 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.25.16_A_0M2A0953_LR.jpg";
import i58 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.23.58_A_0M2A0923_LR.jpg";
import i59 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.23.16_A_0M2A0907_LR.jpg";
import i60 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.22.02_A_0M2A0892_LR.jpg";
import i61 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.21.30_A_0M2A0883_LR.jpg";
import i62 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.21.20_A_0M2A0878_LR.jpg";
import i63 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.21.22_A_0M2A0882_LR.jpg";
import i64 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.21.14_A_0M2A0876_LR.jpg";
import i65 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.21.10_A_0M2A0873_LR.jpg";
import i66 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.21.02_A_0M2A0865_LR.jpg";
import i67 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.21.00_A_0M2A0861_LR.jpg";
import i68 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.56_A_0M2A0858_LR.jpg";
import i69 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.58_C_IMG_0851_LR.jpg";
import i70 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.46_A_0M2A0845_LR.jpg";
import i71 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.50_C_IMG_0841_LR.jpg";
import i72 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.44_C_IMG_0834_LR.jpg";
import i73 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.40_A_0M2A0834_LR.jpg";
import i74 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.42_C_IMG_0832_LR.jpg";
import i75 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.36_C_IMG_0826_LR.jpg";
import i76 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.38_A_0M2A0829_LR.jpg";
import i77 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.34_A_0M2A0824_LR.jpg";
import i78 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.26_A_0M2A0809_LR.jpg";
import i79 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.26_C_IMG_0811_LR.jpg";
import i80 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.02_A_17.20.02_TEG_4228_LR.jpg";
import i81 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.20.00_A_17.20.00_TEG_4227_LR.jpg";
import i82 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.42_A_0M2A0786_LR.jpg";
import i83 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.38_A_0M2A0779_LR.jpg";
import i84 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.22_A_0M2A0772_LR.jpg";
import i85 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.26_C_IMG_0804_LR.jpg";
import i86 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.16_C_IMG_0799_LR.jpg";
import i87 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.14_A_0M2A0765_LR.jpg";
import i88 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.14_C_IMG_0796_LR.jpg";
import i89 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.10_A_0M2A0764_LR.jpg";
import i90 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.10_C_IMG_0792_LR.jpg";
import i91 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.19.04_C_IMG_0791_LR.jpg";
import i92 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.18.44_C_IMG_0785_LR.jpg";
import i93 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.18.38_C_IMG_0782_LR.jpg";
import i94 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.18.30_C_IMG_0774_LR.jpg";
import i95 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.18.28_C_IMG_0772_LR.jpg";
import i96 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.17.48_C_IMG_0768_LR.jpg";
import i97 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.17.22_A_0M2A0755_LR.jpg";
import i98 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.16.30_C_IMG_0761_LR.jpg";
import i99 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.15.12_A_0M2A0752_LR.jpg";
import i100 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.15.48_C_IMG_0759_LR.jpg";
import i101 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.14.46_A_TEG_4217_LR.jpg";
import i102 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.15.08_C_IMG_0752_LR.jpg";
import i103 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.14.12_A_TEG_4200_LR.jpg";
import i104 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.14.18_A_TEG_4204_LR.jpg";
import i105 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.14.42_A_TEG_4216_LR.jpg";
import i106 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.13.40_A_TEG_4185_LR.jpg";
import i107 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.13.20_A_TEG_4172_LR.jpg";
import i108 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.13.16_C_IMG_0748_LR.jpg";
import i109 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.13.00_A_TEG_4169_LR.jpg";
import i110 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.12.58_A_TEG_4167_LR.jpg";
import i111 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.12.24_A_0M2A0742_LR.jpg";
import i112 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.11.28_A_0M2A0727_LR.jpg";
import i113 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.12.20_A_TEG_4161_LR.jpg";
import i114 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.09.46_A_0M2A0703_LR.jpg";
import i115 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.09.08_A_0M2A0686_LR.jpg";
import i116 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.08.40_A_0M2A0669_LR.jpg";
import i117 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.07.48_C_IMG_0720_LR.jpg";
import i118 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.08.38_A_0M2A0665_LR.jpg";
import i119 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.07.44_C_IMG_0717_LR.jpg";
import i120 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.07.30_A_TEG_4145_LR.jpg";
import i121 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.07.26_C_IMG_0715_LR.jpg";
import i122 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.07.16_A_0M2A0644_LR.jpg";
import i123 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.06.38_A_TEG_4135_LR.jpg";
import i124 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.05.38_E_IMG_0263_LR.jpg";
import i125 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.04.12_C_IMG_0708_LR.jpg";
import i126 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.02.06_A_TEG_4120_LR.jpg";
import i127 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.01.46_A_0M2A0621_LR.jpg";
import i128 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.01.28_A_0M2A0618_LR.jpg";
import i129 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.01.18_A_0M2A0613_LR.jpg";
import i130 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.01.26_A_0M2A0617_LR.jpg";
import i131 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.01.12_A_0M2A0611_LR.jpg";
import i132 from "../../assets/img/EAR_RALLYE_Japoma_2024/17.00.28_A_TEG_4117_LR.jpg";
import i133 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.59.46_A_TEG_4107_LR.jpg";
import i134 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.59.40_A_TEG_4104_LR.jpg";
import i135 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.59.38_A_TEG_4102_LR.jpg";
import i136 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.59.26_C_IMG_0697_LR.jpg";
import i137 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.59.00_A_TEG_4096_LR.jpg";
import i138 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.58.40_A_TEG_4093_LR.jpg";
import i139 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.58.20_A_TEG_4087_LR.jpg";
import i140 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.57.26_A_TEG_4080_LR.jpg";
import i141 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.55.34_A_TEG_4073_LR.jpg";
import i142 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.56.10_A_TEG_4075_LR.jpg";
import i143 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.55.04_A_TEG_4071_LR.jpg";
import i144 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.55.08_A_TEG_4072_LR.jpg";
import i145 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.54.30_A_TEG_4067_LR.jpg";
import i146 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.54.06_C_IMG_0690_LR.jpg";
import i147 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.52.44_E_IMG_0223_LR.jpg";
import i148 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.52.26_A_TEG_4060_LR.jpg";
import i149 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.50.32_A_TEG_4049_LR.jpg";
import i150 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.49.34_A_TEG_4046_LR.jpg";
import i151 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.48.04_A_TEG_4045_LR.jpg";
import i152 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.47.58_A_TEG_4043_LR.jpg";
import i153 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.46.42_A_0M2A0574_LR.jpg";
import i154 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.46.00_A_TEG_4041_LR.jpg";
import i155 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.45.52_A_TEG_4040_LR.jpg";
import i156 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.45.08_A_TEG_4035_LR.jpg";
import i157 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.44.56_A_TEG_4033_LR.jpg";
import i158 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.44.52_A_TEG_4032_LR.jpg";
import i159 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.43.08_A_0M2A0563_LR.jpg";
import i160 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.44.32_A_TEG_4030_LR.jpg";
import i161 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.44.20_A_TEG_4028_LR.jpg";
import i162 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.43.18_A_TEG_4027_LR.jpg";
import i163 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.42.46_A_TEG_4021_LR.jpg";
import i164 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.42.04_A_TEG_4016_LR.jpg";
import i165 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.40.06_A_TEG_3998_LR.jpg";
import i166 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.40.14_A_TEG_4000_LR.jpg";
import i167 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.39.50_A_TEG_3994_LR.jpg";
import i168 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.36.56_A_TEG_3988_LR.jpg";
import i169 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.35.08_A_TEG_3973_LR.jpg";
import i170 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.34.52_A_TEG_3971_LR.jpg";
import i171 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.34.46_A_TEG_3969_LR.jpg";
import i172 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.34.14_A_TEG_3960_LR.jpg";
import i173 from "../../assets/img/EAR_RALLYE_Japoma_2024/16.34.16_A_TEG_3962_LR.jpg";
import i174 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.47.24_C_IMG_0662_LR.jpg";
import i175 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.26.54_A_0M2A0536_LR.jpg";
import i176 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.26.20_A_0M2A0533_LR.jpg";
import i177 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.19.12_C_IMG_0635_LR.jpg";
import i178 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.18.48_C_IMG_0629_LR.jpg";
import i179 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.08.56_E_IMG_0170_LR.jpg";
import i180 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.18.34_C_IMG_0628_LR.jpg";
import i181 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.00.56_E_IMG_0155_LR.jpg";
import i182 from "../../assets/img/EAR_RALLYE_Japoma_2024/15.04.26_E_IMG_0162_LR.jpg";
import i183 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.55.24_A_0M2A0465_LR.jpg";
import i184 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.55.22_A_0M2A0459_LR.jpg";
import i185 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.54.42_A_0M2A0451_LR.jpg";
import i186 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.53.06_A_0M2A0447_LR.jpg";
import i187 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.52.34_A_0M2A0445_LR.jpg";
import i188 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.52.16_A_0M2A0442_LR.jpg";
import i189 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.51.10_A_0M2A0425_LR.jpg";
import i190 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.51.18_C_IMG_0622_LR.jpg";
import i191 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.48.48_A_0M2A0423_LR.jpg";
import i192 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.47.58_A_0M2A0412_LR.jpg";
import i193 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.45.55_B_TEG_3935_LR.jpg";
import i194 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.45.42_B_TEG_3933_LR.jpg";
import i195 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.45.21_B_TEG_3929_LR.jpg";
import i196 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.44.44_C_IMG_0612_LR.jpg";
import i197 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.44.44_C_IMG_0611_LR.jpg";
import i198 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.44.42_C_IMG_0610_LR.jpg";
import i199 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.44.40_A_0M2A0397_LR.jpg";
import i200 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.44.38_A_0M2A0391_LR.jpg";
import i201 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.43.50_C_IMG_0607_LR.jpg";
import i202 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.43.32_B_TEG_3922_LR.jpg";
import i203 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.42.56_A_0M2A0387_LR.jpg";
import i204 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.42.38_A_0M2A0378_LR.jpg";
import i205 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.42.36_A_0M2A0376_LR.jpg";
import i206 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.42.34_B_TEG_3917_LR.jpg";
import i207 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.42.04_A_0M2A0362_LR.jpg";
import i208 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.42.19_B_TEG_3912_LR.jpg";
import i209 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.41.02_C_IMG_0605_LR.jpg";
import i210 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.41.00_A_0M2A0343_LR.jpg";
import i211 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.41.04_C_IMG_0606_LR.jpg";
import i212 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.40.58_A_0M2A0334_LR.jpg";
import i213 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.39.30_B_TEG_3909_LR.jpg";
import i214 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.37.50_C_IMG_0599_LR.jpg";
import i215 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.37.50_C_IMG_0598_LR.jpg";
import i216 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.36.19_B_TEG_3906_LR.jpg";
import i217 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.36.10_A_0M2A0306_LR.jpg";
import i218 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.34.46_A_0M2A0273_LR.jpg";
import i219 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.33.34_A_0M2A0261_LR.jpg";
import i220 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.33.14_B_TEG_3893_LR.jpg";
import i221 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.32.56_A_0M2A0256_LR.jpg";
import i222 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.32.30_A_0M2A0241_LR.jpg";
import i223 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.32.18_A_0M2A0237_LR.jpg";
import i224 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.30.54_A_0M2A0224_LR.jpg";
import i225 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.30.24_A_0M2A0219_LR.jpg";
import i226 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.27.44_A_0M2A0198_LR.jpg";
import i227 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.29.36_C_IMG_0589_LR.jpg";
import i228 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.25.46_C_IMG_0584_LR.jpg";
import i229 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.25.46_C_IMG_0582_LR.jpg";
import i230 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.24.32_A_0M2A0183_LR.jpg";
import i231 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.23.11_B_TEG_3879_LR.jpg";
import i232 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.22.46_A_0M2A0169_LR.jpg";
import i233 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.20.45_B_TEG_3877_LR.jpg";
import i234 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.20.25_B_TEG_3875_LR.jpg";
import i235 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.19.04_A_0M2A0148_LR.jpg";
import i236 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.19.08_C_IMG_0577_LR.jpg";
import i237 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.18.50_B_TEG_3872_LR.jpg";
import i238 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.18.03_B_TEG_3870_LR.jpg";
import i239 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.17.26_A_0M2A0139_LR.jpg";
import i240 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.15.52_A_0M2A0128_LR.jpg";
import i241 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.14.44_B_TEG_3867_LR.jpg";
import i242 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.13.04_C_IMG_0570_LR.jpg";
import i243 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.11.16_A_0M2A0113_LR.jpg";
import i244 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.06.00_A_0M2A0091_LR.jpg";
import i245 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.05.08_A_0M2A0088_LR.jpg";
import i246 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.04.36_A_0M2A0081_LR.jpg";
import i247 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.04.26_A_0M2A0077_LR.jpg";
import i248 from "../../assets/img/EAR_RALLYE_Japoma_2024/14.04.10_A_0M2A0074_LR.jpg";
import i249 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.48.04_A_0M2A0027_LR.jpg";
import i250 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.47.08_A_0M2A9989_LR.jpg";
import i251 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.47.46_A_0M2A0021_LR.jpg";
import i252 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.40.58_A_0M2A9982_LR.jpg";
import i253 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.39.42_A_0M2A9975_LR.jpg";
import i254 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.32.14_C_IMG_0544_LR.jpg";
import i255 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.35.58_A_0M2A9945_LR.jpg";
import i256 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.19.18_A_0M2A9826_LR.jpg";
import i257 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.19.16_C_IMG_0526_LR.jpg";
import i258 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.18.36_A_0M2A9804_LR.jpg";
import i259 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.17.46_C_IMG_0517_LR.jpg";
import i260 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.17.26_A_0M2A9769_LR.jpg";
import i261 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.13.42_C_IMG_0497_LR.jpg";
import i262 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.12.58_C_IMG_0494_LR.jpg";
import i263 from "../../assets/img/EAR_RALLYE_Japoma_2024/13.06.32_C_IMG_0490_LR.jpg";
import i264 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.58.04_C_IMG_0488_LR.jpg";
import i265 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.56.04_C_IMG_0485_LR.jpg";
import i266 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.53.28_C_IMG_0470_LR.jpg";
import i267 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.52.48_C_IMG_0467_LR.jpg";
import i268 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.52.44_C_IMG_0466_LR.jpg";
import i269 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.46.02_A_0M2A9752_LR.jpg";
import i270 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.46.00_C_IMG_0462_LR.jpg";
import i271 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.44.16_A_0M2A9703_LR.jpg";
import i272 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.44.10_A_0M2A9701_LR.jpg";
import i273 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.44.04_A_0M2A9698_LR.jpg";
import i274 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.43.56_A_0M2A9692_LR.jpg";
import i275 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.44.02_A_0M2A9697_LR.jpg";
import i276 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.43.46_A_0M2A9686_LR.jpg";
import i277 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.39.56_C_IMG_0446_LR.jpg";
import i278 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.40.00_B_TEG_3817_LR.jpg";
import i279 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.33.05_B_TEG_3782_LR.jpg";
import i280 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.32.52_A_0M2A9671_LR.jpg";
import i281 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.32.32_C_IMG_0438_LR.jpg";
import i282 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.31.40_D_JI9C2503_LR.jpg";
import i283 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.31.24_A_0M2A9662_LR.jpg";
import i284 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.30.10_A_0M2A9653_LR.jpg";
import i285 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.26.34_A_0M2A9638_LR.jpg";
import i286 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.26.26_C_IMG_0429_LR.jpg";
import i287 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.18.44_B_TEG_3768_LR.jpg";
import i288 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.17.34_A_0M2A9600_LR.jpg";
import i289 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.16.52_C_IMG_0407_LR.jpg";
import i290 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.11.21_B_TEG_3764_LR.jpg";
import i291 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.11.17_B_TEG_3763_LR.jpg";
import i292 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.11.04_B_TEG_3755_LR.jpg";
import i293 from "../../assets/img/EAR_RALLYE_Japoma_2024/12.02.20_D_JI9C2336_LR.jpg";
import i294 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.58.32_A_0M2A9527_LR.jpg";
import i295 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.58.21_B_TEG_3744_LR.jpg";
import i296 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.58.18_B_TEG_3742_LR.jpg";
import i297 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.58.10_A_0M2A9520_LR.jpg";
import i298 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.58.15_B_TEG_3740_LR.jpg";
import i299 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.58.11_B_TEG_3738_LR.jpg";
import i300 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.58.07_B_TEG_3735_LR.jpg";
import i301 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.57.54_B_TEG_3734_LR.jpg";
import i302 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.57.51_B_TEG_3732_LR.jpg";
import i303 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.56.23_B_TEG_3718_LR.jpg";
import i304 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.50_C_IMG_0403_LR.jpg";
import i305 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.32_C_IMG_0401_LR.jpg";
import i306 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.26_C_IMG_0400_LR.jpg";
import i307 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.27_B_TEG_3678_LR.jpg";
import i308 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.20_C_IMG_0398_LR.jpg";
import i309 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.16_C_IMG_0397_LR.jpg";
import i310 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.12_C_IMG_0395_LR.jpg";
import i311 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.52.08_C_IMG_0393_LR.jpg";
import i312 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.51.42_C_IMG_0390_LR.jpg";
import i313 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.51.28_C_IMG_0387_LR.jpg";
import i314 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.51.04_C_IMG_0379_LR.jpg";
import i315 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.50.52_B_TEG_3672_LR.jpg";
import i316 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.46.38_A_0M2A9475_LR.jpg";
import i317 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.46.29_B_TEG_3658_LR.jpg";
import i318 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.46.16_A_0M2A9472_LR.jpg";
import i319 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.46.14_A_0M2A9470_LR.jpg";
import i320 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.44.52_D_JI9C2279_LR.jpg";
import i321 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.44.14_A_0M2A9457_LR.jpg";
import i322 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.43.02_A_0M2A9454_LR.jpg";
import i323 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.42.04_A_0M2A9445_LR.jpg";
import i324 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.41.50_A_0M2A9443_LR.jpg";
import i325 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.41.52_C_IMG_0375_LR.jpg";
import i326 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.31.14_D_JI9C2208_LR.jpg";
import i327 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.33.34_E_IMG_9742_LR.jpg";
import i328 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.03.56_A_0M2A9421_LR.jpg";
import i329 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.08.02_D_JI9C2200_LR.jpg";
import i330 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.03.04_D_JI9C2180_LR.jpg";
import i331 from "../../assets/img/EAR_RALLYE_Japoma_2024/11.01.24_C_IMG_0355_LR.jpg";
import i332 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.57.00_A_0M2A9378_LR.jpg";
import i333 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.50.38_A_0M2A9340_LR.jpg";
import i334 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.50.22_A_0M2A9332_LR.jpg";
import i335 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.49.34_A_0M2A9265_LR.jpg";
import i336 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.49.04_A_0M2A9250_LR.jpg";
import i337 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.48.42_A_0M2A9241_LR.jpg";
import i338 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.48.06_A_0M2A9224_LR.jpg";
import i339 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.47.08_A_0M2A9218_LR.jpg";
import i340 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.46.42_A_0M2A9213_LR.jpg";
import i341 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.45.18_A_0M2A9197_LR.jpg";
import i342 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.38.23_B_TEG_3638_LR.jpg";
import i343 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.29.58_A_0M2A9163_LR.jpg";
import i344 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.29.16_B_TEG_3629_LR.jpg";
import i345 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.28.54_A_0M2A9147_LR.jpg";
import i346 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.28.36_A_0M2A9144_LR.jpg";
import i347 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.26.37_B_TEG_3619_LR.jpg";
import i348 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.15.41_B_TEG_3593_LR.jpg";
import i349 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.14.38_C_IMG_0318_LR.jpg";
import i350 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.11.04_A_0M2A9063_LR.jpg";
import i351 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.09.31_B_TEG_3586_LR.jpg";
import i352 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.08.59_B_TEG_3583_LR.jpg";
import i353 from "../../assets/img/EAR_RALLYE_Japoma_2024/10.03.00_A_0M2A9051_LR.jpg";
import i354 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.53.44_A_0M2A9041_LR.jpg";
import i355 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.53.54_A_0M2A9046_LR.jpg";
import i356 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.51.36_A_0M2A9032_LR.jpg";
import i357 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.52.16_A_0M2A9037_LR.jpg";
import i358 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.51.34_A_0M2A9031_LR.jpg";
import i359 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.51.26_A_0M2A9028_LR.jpg";
import i360 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.49.44_A_0M2A9010_LR.jpg";
import i361 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.45.34_A_0M2A8995_LR.jpg";
import i362 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.43.18_D_JI9C2101_LR.jpg";
import i363 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.42.48_A_0M2A8982_LR.jpg";
import i364 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.43.16_D_JI9C2099_LR.jpg";
import i365 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.43.14_A_0M2A8987_LR.jpg";
import i366 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.43.12_D_JI9C2097_LR.jpg";
import i367 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.40.20_C_IMG_0279_LR.jpg";
import i368 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.39.24_A_0M2A8941_LR.jpg";
import i369 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.38.46_A_0M2A8934_LR.jpg";
import i370 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.38.26_A_0M2A8928_LR.jpg";
import i371 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.38.10_A_0M2A8924_LR.jpg";
import i372 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.38.02_A_0M2A8921_LR.jpg";
import i373 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.37.46_A_0M2A8919_LR.jpg";
import i374 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.37.36_A_0M2A8916_LR.jpg";
import i375 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.37.24_A_0M2A8914_LR.jpg";
import i376 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.37.14_A_0M2A8912_LR.jpg";
import i377 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.36.46_A_0M2A8906_LR.jpg";
import i378 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.36.52_A_0M2A8908_LR.jpg";
import i379 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.36.34_A_0M2A8899_LR.jpg";
import i380 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.36.24_A_0M2A8895_LR.jpg";
import i381 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.36.22_D_JI9C2067_LR.jpg";
import i382 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.36.00_A_0M2A8887_LR.jpg";
import i383 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.35.50_A_0M2A8885_LR.jpg";
import i384 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.35.40_A_0M2A8883_LR.jpg";
import i385 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.35.34_A_0M2A8877_LR.jpg";
import i386 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.35.10_A_0M2A8870_LR.jpg";
import i387 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.32.26_C_IMG_0260_LR.jpg";
import i388 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.28.52_A_0M2A8843_LR.jpg";
import i389 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.28.46_A_0M2A8837_LR.jpg";
import i390 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.28.42_A_0M2A8835_LR.jpg";
import i391 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.28.34_A_0M2A8831_LR.jpg";
import i392 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.27.28_D_JI9C2024_LR.jpg";
import i393 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.27.22_A_0M2A8819_LR.jpg";
import i394 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.18.42_A_0M2A8806_LR.jpg";
import i397 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.16.22_A_0M2A8786_LR.jpg";
import i398 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.16.34_C_IMG_0240_LR.jpg";
import i399 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.14.56_A_0M2A8776_LR.jpg";
import i400 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.14.12_A_0M2A8772_LR.jpg";
import i401 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.13.34_A_0M2A8769_LR.jpg";
import i402 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.13.56_A_0M2A8770_LR.jpg";
import i403 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.12.58_A_0M2A8764_LR.jpg";
import i404 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.12.14_A_0M2A8760_LR.jpg";
import i405 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.11.46_A_0M2A8759_LR.jpg";
import i406 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.10.20_A_0M2A8753_LR.jpg";
import i407 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.05.48_A_0M2A8750_LR.jpg";
import i408 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.05.26_A_0M2A8748_LR.jpg";
import i409 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.04.38_A_0M2A8746_LR.jpg";
import i410 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.03.12_A_0M2A8737_LR.jpg";
import i411 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.01.18_A_0M2A8732_LR.jpg";
import i412 from "../../assets/img/EAR_RALLYE_Japoma_2024/09.00.22_A_0M2A8722_LR.jpg";
import i413 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.59.28_A_0M2A8708_LR.jpg";
import i414 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.59.40_C_IMG_0236_LR.jpg";
import i415 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.59.12_C_IMG_0234_LR.jpg";
import i416 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.59.12_C_IMG_0233_LR.jpg";
import i417 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.59.08_C_IMG_0232_LR.jpg";
import i418 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.59.08_A_0M2A8705_LR.jpg";
import i419 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.58.58_C_IMG_0231_LR.jpg";
import i420 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.58.10_A_0M2A8695_LR.jpg";
import i421 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.58.14_A_0M2A8699_LR.jpg";
import i422 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.58.18_C_IMG_0230_LR.jpg";
import i423 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.58.00_C_IMG_0229_LR.jpg";
import i424 from "../../assets/img/EAR_RALLYE_Japoma_2024/08.57.50_C_IMG_0228_LR.jpg";
import i425 from "../../assets/img/EAR_RALLYE_Japoma_2024/1_Rooftop/14.41.52_A_0M2A0350_LR.jpg";
import i426 from "../../assets/img/EAR_RALLYE_Japoma_2024/1_Rooftop/13.36.36_A_0M2A9951_LR.jpg";
import i427 from "../../assets/img/EAR_RALLYE_Japoma_2024/1_Rooftop/Rooftop3.png";
import i428 from "../../assets/img/EAR_RALLYE_Japoma_2024/1_Rooftop/Rooftop2.png";
import i429 from "../../assets/img/EAR_RALLYE_Japoma_2024/1_Rooftop/Rooftop.png";
import i430 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/17.28.18_A_0M2A0989_LR.jpg";
import i431 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/17.26.12_C_IMG_0867_LR.jpg";
import i432 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/17.24.00_C_IMG_0862_LR.jpg";
import i433 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/17.12.16_A_TEG_4159_LR.jpg";
import i434 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/16.39.50_A_0M2A0555_LR.jpg";
import i435 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/16.36.34_A_TEG_3984_LR.jpg";
import i436 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/14.41.58_A_0M2A0355_LR.jpg";
import i437 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/16.31.26_A_TEG_3952_LR.jpg";
import i438 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/17.23.56_A_0M2A0922_LR.jpg";
import i439 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/17.25.00_A_0M2A0945_LR.jpg";
import i440 from "../../assets/img/EAR_RALLYE_Japoma_2024/Afrijet/17.28.18_A_0M2A0989_LR.jpg";


// iMPORT GOLF IMAGES

import g1 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.16.39.jpeg'
import g2 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.16.15.jpeg'
import g3 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.15.36-6.jpeg'
import g4 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.15.36-5.jpeg'
import g5 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.15.36-4.jpeg'
import g6 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.15.36-3.jpeg'
import g7 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.15.36-2.jpeg'
import g8 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.15.36.jpeg'
import g9 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.54-5.jpeg'
import g10 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.54-4.jpeg'
import g11 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.54-3.jpeg'
import g12 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.54-2.jpeg'
import g13 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.54.jpeg'
import g14 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.53-5.jpeg'
import g15 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.53-4.jpeg'
import g16 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.53-3.jpeg'
import g17 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.53-2.jpeg'
import g18 from '../../assets/img/EAR_RALLYE_Japoma_2024/GOLF/16.14.53.jpeg'






const imgRallye=[
    {img:i01, link:"link"},
    {img:i02, link:"link"},
    {img:i03, link:"link"},
    {img:i04, link:"link"},
    {img:i05, link:"link"},
    {img:i06, link:"link"},
    {img:i07, link:"link"},
    {img:i08, link:"link"},
    {img:i09, link:"link"},
    {img:i10, link:"link"},
    {img:i11, link:"link"},
    {img:i12, link:"link"},
    {img:i13, link:"link"},
    {img:i14, link:"link"},
    {img:i15, link:"link"},
    {img:i16, link:"link"},
    {img:i17, link:"link"},
    {img:i18, link:"link"},
    {img:i19, link:"link"},
    {img:i20, link:"link"},
    {img:i21, link:"link"},
    {img:i22, link:"link"},
    {img:i23, link:"link"},
    {img:i24, link:"link"},
    {img:i25, link:"link"},
    {img:i26, link:"link"},
    {img:i27, link:"link"},
    {img:i28, link:"link"},
    {img:i29, link:"link"},
    {img:i30, link:"link"},
    {img:i31, link:"link"},
    {img:i32, link:"link"},
    {img:i33, link:"link"},
    {img:i34, link:"link"},
    {img:i35, link:"link"},
    {img:i36, link:"link"},
    {img:i37, link:"link"},
    {img:i38, link:"link"},
    {img:i39, link:"link"},
    {img:i40, link:"link"},
    {img:i41, link:"link"},
    {img:i42, link:"link"},
    {img:i43, link:"link"},
    {img:i44, link:"link"},
    {img:i45, link:"link"},
    {img:i46, link:"link"},
    {img:i47, link:"link"},
    {img:i48, link:"link"},
    {img:i49, link:"link"},
    {img:i50, link:"link"},
    {img:i51, link:"link"},
    {img:i52, link:"link"},
    {img:i53, link:"link"},
    {img:i54, link:"link"},
    {img:i55, link:"link"},
    {img:i56, link:"link"},
    {img:i57, link:"link"},
    {img:i58, link:"link"},
    {img:i59, link:"link"},
    {img:i60, link:"link"},
    {img:i61, link:"link"},
    {img:i62, link:"link"},
    {img:i63, link:"link"},
    {img:i64, link:"link"},
    {img:i65, link:"link"},
    {img:i66, link:"link"},
    {img:i67, link:"link"},
    {img:i68, link:"link"},
    {img:i69, link:"link"},
    {img:i70, link:"link"},
    {img:i71, link:"link"},
    {img:i72, link:"link"},
    {img:i73, link:"link"},
    {img:i74, link:"link"},
    {img:i75, link:"link"},
    {img:i76, link:"link"},
    {img:i77, link:"link"},
    {img:i78, link:"link"},
    {img:i79, link:"link"},
    {img:i80, link:"link"},
    {img:i81, link:"link"},
    {img:i82, link:"link"},
    {img:i83, link:"link"},
    {img:i84, link:"link"},
    {img:i85, link:"link"},
    {img:i86, link:"link"},
    {img:i87, link:"link"},
    {img:i88, link:"link"},
    {img:i89, link:"link"},
    {img:i90, link:"link"},
    {img:i91, link:"link"},
    {img:i92, link:"link"},
    {img:i93, link:"link"},
    {img:i94, link:"link"},
    {img:i95, link:"link"},
    {img:i96, link:"link"},
    {img:i97, link:"link"},
    {img:i98, link:"link"},
    {img:i99, link:"link"},
    {img:i100, link:"link"},
    {img:i101, link:"link"},
    {img:i102, link:"link"},
    {img:i103, link:"link"},
    {img:i104, link:"link"},
    {img:i105, link:"link"},
    {img:i106, link:"link"},
    {img:i107, link:"link"},
    {img:i108, link:"link"},
    {img:i109, link:"link"},
    {img:i110, link:"link"},
    {img:i111, link:"link"},
    {img:i112, link:"link"},
    {img:i113, link:"link"},
    {img:i114, link:"link"},
    {img:i115, link:"link"},
    {img:i116, link:"link"},
    {img:i117, link:"link"},
    {img:i118, link:"link"},
    {img:i119, link:"link"},
    {img:i120, link:"link"},
    {img:i121, link:"link"},
    {img:i122, link:"link"},
    {img:i123, link:"link"},
    {img:i124, link:"link"},
    {img:i125, link:"link"},
    {img:i126, link:"link"},
    {img:i127, link:"link"},
    {img:i128, link:"link"},
    {img:i129, link:"link"},
    {img:i130, link:"link"},
    {img:i131, link:"link"},
    {img:i132, link:"link"},
    {img:i133, link:"link"},
    {img:i134, link:"link"},
    {img:i135, link:"link"},
    {img:i136, link:"link"},
    {img:i137, link:"link"},
    {img:i138, link:"link"},
    {img:i139, link:"link"},
    {img:i140, link:"link"},
    {img:i141, link:"link"},
    {img:i142, link:"link"},
    {img:i143, link:"link"},
    {img:i144, link:"link"},
    {img:i145, link:"link"},
    {img:i146, link:"link"},
    {img:i147, link:"link"},
    {img:i148, link:"link"},
    {img:i149, link:"link"},
    {img:i150, link:"link"},
    {img:i151, link:"link"},
    {img:i152, link:"link"},
    {img:i153, link:"link"},
    {img:i154, link:"link"},
    {img:i155, link:"link"},
    {img:i156, link:"link"},
    {img:i157, link:"link"},
    {img:i158, link:"link"},
    {img:i159, link:"link"},
    {img:i160, link:"link"},
    {img:i161, link:"link"},
    {img:i162, link:"link"},
    {img:i163, link:"link"},
    {img:i164, link:"link"},
    {img:i165, link:"link"},
    {img:i166, link:"link"},
    {img:i167, link:"link"},
    {img:i168, link:"link"},
    {img:i169, link:"link"},
    {img:i170, link:"link"},
    {img:i171, link:"link"},
    {img:i172, link:"link"},
    {img:i173, link:"link"},
    {img:i174, link:"link"},
    {img:i175, link:"link"},
    {img:i176, link:"link"},
    {img:i177, link:"link"},
    {img:i178, link:"link"},
    {img:i179, link:"link"},
    {img:i180, link:"link"},
    {img:i181, link:"link"},
    {img:i182, link:"link"},
    {img:i183, link:"link"},
    {img:i184, link:"link"},
    {img:i185, link:"link"},
    {img:i186, link:"link"},
    {img:i187, link:"link"},
    {img:i188, link:"link"},
    {img:i189, link:"link"},
    {img:i190, link:"link"},
    {img:i191, link:"link"},
    {img:i192, link:"link"},
    {img:i193, link:"link"},
    {img:i194, link:"link"},
    {img:i195, link:"link"},
    {img:i196, link:"link"},
    {img:i197, link:"link"},
    {img:i198, link:"link"},
    {img:i199, link:"link"},
    {img:i200, link:"link"},
    {img:i201, link:"link"},
    {img:i202, link:"link"},
    {img:i203, link:"link"},
    {img:i204, link:"link"},
    {img:i205, link:"link"},
    {img:i206, link:"link"},
    {img:i207, link:"link"},
    {img:i208, link:"link"},
    {img:i209, link:"link"},
    {img:i210, link:"link"},
    {img:i211, link:"link"},
    {img:i212, link:"link"},
    {img:i213, link:"link"},
    {img:i214, link:"link"},
    {img:i215, link:"link"},
    {img:i216, link:"link"},
    {img:i217, link:"link"},
    {img:i218, link:"link"},
    {img:i219, link:"link"},
    {img:i220, link:"link"},
    {img:i221, link:"link"},
    {img:i222, link:"link"},
    {img:i223, link:"link"},
    {img:i224, link:"link"},
    {img:i225, link:"link"},
    {img:i226, link:"link"},
    {img:i227, link:"link"},
    {img:i228, link:"link"},
    {img:i229, link:"link"},
    {img:i230, link:"link"},
    {img:i231, link:"link"},
    {img:i232, link:"link"},
    {img:i233, link:"link"},
    {img:i234, link:"link"},
    {img:i235, link:"link"},
    {img:i236, link:"link"},
    {img:i237, link:"link"},
    {img:i238, link:"link"},
    {img:i239, link:"link"},
    {img:i240, link:"link"},
    {img:i241, link:"link"},
    {img:i242, link:"link"},
    {img:i243, link:"link"},
    {img:i244, link:"link"},
    {img:i245, link:"link"},
    {img:i246, link:"link"},
    {img:i247, link:"link"},
    {img:i248, link:"link"},
    {img:i249, link:"link"},
    {img:i250, link:"link"},
    {img:i251, link:"link"},
    {img:i252, link:"link"},
    {img:i253, link:"link"},
    {img:i254, link:"link"},
    {img:i255, link:"link"},
    {img:i256, link:"link"},
    {img:i257, link:"link"},
    {img:i258, link:"link"},
    {img:i259, link:"link"},
    {img:i260, link:"link"},
    {img:i261, link:"link"},
    {img:i262, link:"link"},
    {img:i263, link:"link"},
    {img:i264, link:"link"},
    {img:i265, link:"link"},
    {img:i266, link:"link"},
    {img:i267, link:"link"},
    {img:i268, link:"link"},
    {img:i269, link:"link"},
    {img:i270, link:"link"},
    {img:i271, link:"link"},
    {img:i272, link:"link"},
    {img:i273, link:"link"},
    {img:i274, link:"link"},
    {img:i275, link:"link"},
    {img:i276, link:"link"},
    {img:i277, link:"link"},
    {img:i278, link:"link"},
    {img:i279, link:"link"},
    {img:i280, link:"link"},
    {img:i281, link:"link"},
    {img:i282, link:"link"},
    {img:i283, link:"link"},
    {img:i284, link:"link"},
    {img:i285, link:"link"},
    {img:i286, link:"link"},
    {img:i287, link:"link"},
    {img:i288, link:"link"},
    {img:i289, link:"link"},
    {img:i290, link:"link"},
    {img:i291, link:"link"},
    {img:i292, link:"link"},
    {img:i293, link:"link"},
    {img:i294, link:"link"},
    {img:i295, link:"link"},
    {img:i296, link:"link"},
    {img:i297, link:"link"},
    {img:i298, link:"link"},
    {img:i299, link:"link"},
    {img:i300, link:"link"},
    {img:i301, link:"link"},
    {img:i302, link:"link"},
    {img:i303, link:"link"},
    {img:i304, link:"link"},
    {img:i305, link:"link"},
    {img:i306, link:"link"},
    {img:i307, link:"link"},
    {img:i308, link:"link"},
    {img:i309, link:"link"},
    {img:i310, link:"link"},
    {img:i311, link:"link"},
    {img:i312, link:"link"},
    {img:i313, link:"link"},
    {img:i314, link:"link"},
    {img:i315, link:"link"},
    {img:i316, link:"link"},
    {img:i317, link:"link"},
    {img:i318, link:"link"},
    {img:i319, link:"link"},
    {img:i320, link:"link"},
    {img:i321, link:"link"},
    {img:i322, link:"link"},
    {img:i323, link:"link"},
    {img:i324, link:"link"},
    {img:i325, link:"link"},
    {img:i326, link:"link"},
    {img:i327, link:"link"},
    {img:i328, link:"link"},
    {img:i329, link:"link"},
    {img:i330, link:"link"},
    {img:i331, link:"link"},
    {img:i332, link:"link"},
    {img:i333, link:"link"},
    {img:i334, link:"link"},
    {img:i335, link:"link"},
    {img:i336, link:"link"},
    {img:i337, link:"link"},
    {img:i338, link:"link"},
    {img:i339, link:"link"},
    {img:i340, link:"link"},
    {img:i341, link:"link"},
    {img:i342, link:"link"},
    {img:i343, link:"link"},
    {img:i344, link:"link"},
    {img:i345, link:"link"},
    {img:i346, link:"link"},
    {img:i347, link:"link"},
    {img:i348, link:"link"},
    {img:i349, link:"link"},
    {img:i350, link:"link"},
    {img:i351, link:"link"},
    {img:i352, link:"link"},
    {img:i353, link:"link"},
    {img:i354, link:"link"},
    {img:i355, link:"link"},
    {img:i356, link:"link"},
    {img:i357, link:"link"},
    {img:i358, link:"link"},
    {img:i359, link:"link"},
    {img:i360, link:"link"},
    {img:i361, link:"link"},
    {img:i362, link:"link"},
    {img:i363, link:"link"},
    {img:i364, link:"link"},
    {img:i365, link:"link"},
    {img:i366, link:"link"},
    {img:i367, link:"link"},
    {img:i368, link:"link"},
    {img:i369, link:"link"},
    {img:i370, link:"link"},
    {img:i371, link:"link"},
    {img:i372, link:"link"},
    {img:i373, link:"link"},
    {img:i374, link:"link"},
    {img:i375, link:"link"},
    {img:i376, link:"link"},
    {img:i377, link:"link"},
    {img:i378, link:"link"},
    {img:i379, link:"link"},
    {img:i380, link:"link"},
    {img:i381, link:"link"},
    {img:i382, link:"link"},
    {img:i383, link:"link"},
    {img:i384, link:"link"},
    {img:i385, link:"link"},
    {img:i386, link:"link"},
    {img:i387, link:"link"},
    {img:i388, link:"link"},
    {img:i389, link:"link"},
    {img:i390, link:"link"},
    {img:i391, link:"link"},
    {img:i392, link:"link"},
    {img:i393, link:"link"},
    {img:i394, link:"link"},
    {img:i397, link:"link"},
    {img:i398, link:"link"},
    {img:i399, link:"link"},
    {img:i400, link:"link"},
    {img:i401, link:"link"},
    {img:i402, link:"link"},
    {img:i403, link:"link"},
    {img:i404, link:"link"},
    {img:i405, link:"link"},
    {img:i406, link:"link"},
    {img:i407, link:"link"},
    {img:i408, link:"link"},
    {img:i409, link:"link"},
    {img:i410, link:"link"},
    {img:i411, link:"link"},
    {img:i412, link:"link"},
    {img:i413, link:"link"},
    {img:i414, link:"link"},
    {img:i415, link:"link"},
    {img:i416, link:"link"},
    {img:i417, link:"link"},
    {img:i418, link:"link"},
    {img:i419, link:"link"},
    {img:i420, link:"link"},
    {img:i421, link:"link"},
    {img:i422, link:"link"},
    {img:i423, link:"link"},
    {img:i424, link:"link"},
    {img:i425, link:"link"},
    {img:i426, link:"link"},
    {img:i427, link:"link"},
    {img:i428, link:"link"},
    {img:i429, link:"link"},
    {img:i430, link:"link"},
    {img:i431, link:"link"},
    {img:i432, link:"link"},
    {img:i433, link:"link"},
    {img:i434, link:"link"},
    {img:i435, link:"link"},
    {img:i436, link:"link"},
    {img:i437, link:"link"},
    {img:i438, link:"link"},
    {img:i439, link:"link"},
    {img:i440, link:"link"},
]

const imgGolf = [
    {img: g1},
    {img: g2},
    {img: g3},
    {img: g4},
    {img: g5},
    {img: g6},
    {img: g7},
    {img: g8},
    {img: g9},
    {img: g10},
    {img: g11},
    {img: g12},
    {img: g13},
    {img: g14},
    {img: g15},
    {img: g16},
    {img: g17},
    {img: g18},
]

const Japoma2024 = () => {


    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
        window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ block: "start", inline: "start" });
            }
        }, 0);
        }
    }, [pathname, hash, key]); 



    const [img, setImg] = useState(null)
    const [link, setLink] = useState(null)
    const [index, setIndex] = useState(null)

    const getImg = (elt, index) =>{
        setImg(elt)
        setIndex(index)
    }

    const rotateRight = () =>{
        const totalLength = imgRallye.length;
        if (index + 1 >= totalLength) {
            setIndex(0)
            const newUrl = imgRallye[0].img
            setImg(newUrl)
            return;
        }
        setImg(imgRallye[index+ 1].img)
        setLink(imgRallye[index+ 1].link)
        setIndex(index+1)
    }
    
    const rotateLeft = () =>{
        const totalLength = imgRallye.length;
        if (index + 1 <= 1) {
            setIndex(totalLength)
            const newUrl = imgRallye[totalLength-1].img
            setImg(newUrl)
            return;
        }
        setIndex(index-1)
        setLink(imgRallye[index - 1].link)
        setImg(imgRallye[index - 1].img)
    }

    const handleSave = (elt) =>{
        fetch(elt)
        .then(response => response.blob())
        .then(blob => {
            // create a temporary anchor element
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url

            // Extract the filename from URL
            const filename = elt.substring(elt.lastIndexOf('/')+ 1);

            // Set the download attribute and filename
            link.setAttribute('download', filename);
            document.body.appendChild(link);

            // Simulate a click on the anchor element to start the download
            link.click();
        })
    }
    
    return (
        <>
            <div className='album mt-5 container'>
                <div className=' pt-5 mt-5'>
                    <Title text="RALLYE GT JAPOMA 2024 EN IMAGE"/>
                </div>
                <Tab.Container defaultActiveKey={'1'}>
                    <Row className='position-relative'>
                        <Col sm={12} className='position-sticky nav-member bg-black py-2'>
                            <Nav variant="pills" className="justify-content-center position-relative pb-2">
                                <Nav.Item>
                                    <Nav.Link className='m-0 px-md-1 w-100' eventKey={'1'}>RALLYE GT</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='m-0 px-md-1 w-100' eventKey={'2'}>GOLF OPEN</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={12} >
                            <Tab.Content className = 'mx-auto'>
                                <Tab.Pane eventKey={'1'} >
                                    <div className='gallery'>
                                        {
                                            imgRallye.map((elt,index)=>{
                                                return(
                                                    <div className="pics position-relative" key={index} >
                                                        <div className='download bg-dark bg-opacity-75 w-100 h-100 position-absolute justify-content-around align-items-center'>
                                                            <div role={'button'} onClick={()=>{getImg(elt.img, imgRallye.indexOf(elt));setLink(elt.img)}} className=' d-flex flex-column justify-content-center align-items-center'>
                                                                <i className='fa fa-arrows-to-eye fs-2 text-white'></i>
                                                                <small>Voir plus</small>
                                                            </div>
                                                            <div role='button' onClick={()=> handleSave(elt.img)} className=' d-flex flex-column justify-content-center align-items-center'>
                                                                <i className='fa fa-file-arrow-down fs-1 text-white'></i>
                                                                <small>Télécharger</small>
                                                            </div>
                                                        </div>
                                                        <img src={elt.img} alt={'Event AutoStop Rallye GT'} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'} >
                                    <div className='gallery'>
                                        {
                                            imgGolf.map((elt,index)=>{
                                                return(
                                                    <div className="pics position-relative" key={index} >
                                                        <div className='download bg-dark bg-opacity-75 w-100 h-100 position-absolute justify-content-around align-items-center'>
                                                            <div role={'button'} onClick={()=>{getImg(elt.img, imgRallye.indexOf(elt));setLink(elt.img)}} className=' d-flex flex-column justify-content-center align-items-center'>
                                                                <i className='fa fa-arrows-to-eye fs-2 text-white'></i>
                                                                <small>Voir plus</small>
                                                            </div>
                                                            <div role='button' onClick={()=> handleSave(elt.img)} className=' d-flex flex-column justify-content-center align-items-center'>
                                                                <i className='fa fa-file-arrow-down fs-1 text-white'></i>
                                                                <small>Télécharger</small>
                                                            </div>
                                                        </div>
                                                        <img src={elt.img} alt={'Event AutoStop Rallye GT'} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                {img && <Expand img={img} link={link} rotateRight={rotateRight} rotateLeft={rotateLeft} setImg={setImg} handleSave={handleSave} />}
            </div>
        </>
    )
}

export default Japoma2024




const Expand = (props) =>{

    const handleClick = (e) =>{
        if (e.target.classList.contains("dismiss")) {
            props.setImg(null)
        }
    }

    return(
        <>
            <div className='overlay dismiss p-0' onClick={handleClick} >
                <div onClick={props.rotateRight} className='overlay-arrows__right'>
                    {" "}
                    <i className='ri-arrow-right-circle-fill ri ri-2x text-white'></i>
                </div>
                <div onClick={props.rotateLeft} className='overlay-arrows__left'>
                    {" "}
                    <i className='ri-arrow-left-circle-fill ri ri-2x text-white'></i>
                </div>
                <div className='position-relative mx-auto h-100 d-flex justify-content-center align-items-center'>
                    <img src={props.img} alt='EAR JAPOMA 2024' className='img-fluid'/>
                </div>
                <span role={'button'} className='position-absolute' >
                    <i className="ri-2x aer-color ri ri-close-circle-fill dismiss" onClick={()=>props.setImg(null)} ></i>
                    <div role='button' onClick={()=> props.handleSave(props.link)} className=' d-flex flex-column'>
                        <i className='fa fa-cloud-arrow-down fs-3 color-primary'></i>
                        {/* <small>Télécharger</small> */}
                    </div>
                </span>
            </div>
        </>
    )
}
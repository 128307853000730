import React from 'react'

const Reload = () => {
    return (
        <div className='loading-page d-flex justify-content-center align-items-center'>
            <div className='text-center'>
                <i className='ri-error-warning-fill ri-10x text-danger'></i>
                <p className='mt-4'>Une erreur c'est produite. <br/> Veuillez vérifier votre connexion internet et réessayer ! </p>
            </div>
        </div>
    )
}

export default Reload
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Tab, Offcanvas, Accordion } from 'react-bootstrap'
import a1 from '../../assets/img/actus/herman.webp'
import a2 from '../../assets/img/actus/voitures.webp'
import a3 from '../../assets/img/actus/maire.webp'
import a4 from '../../assets/img/actus/gagnant.webp'
import img from '../../assets/img/LOGO EAR 2024-W.png'
import img2 from '../../assets/img/actus/maire.webp'
import about1 from '../../assets/img/2024-EAR-RALLYE-GT.webp'
// import about2 from '../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.22.34_IMG_4565_LR.webp'
import about3 from '../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6838_LR.webp';
import i1 from '../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_13.07.14_IMG_5157_LR.webp';
import i2 from '../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.22.24_IMG_4559_LR.webp';
import i3 from '../../assets/img/annount.jpg';
import e1 from '../../assets/img/trio-gagnant-EAR-2023.webp'
import e2 from '../../assets/img/trio_vainqueur_EAR_japoma_2024.png'
import imgNav from '../../assets/img/voiture dorée.png'
import { AppContexte } from '../../context/context'

const Header = () => {

    const { categorie } = useContext(AppContexte)

    const links = [
        {nom:'accueil', path:'/', },
        {nom:'event',  sub:[
            {img: e2 , nom: 'EAR 2024 douala', path:`/event/ear-japoma-2024#home-timer`},
            {img: e1 , nom: 'EAR 2023 kribi', path:`/event/ear-kribi-2023`},
            {img: img , nom: 'EAR 2025', path:`/soon`},
        ]},
        {nom:'à propos', sub:[
            {img:about1 , nom:'contexte', path:'/contexte'},
            // {img:about2 , nom:'association EAR', path:'/2'},
            {img:about3 , nom:'membres', path:'/membres'},
        ]},
        // {nom:'event', lat:true, sub:[
        //     {nom:'EAR 2023 kribi', subPaths:[
        //         {photo:"/photo",  video: "/video", presse: "/presse"},
        //     ]},
        //     {nom:' EAR 2024 - douala', subPaths:[
        //         {photo:"/photo",  video: "/video", presse: "/presse"},
        //     ]},
        //     {nom:' EAR 2024 - kribi', subPaths:[
        //         {photo:"/photo",  video: "/video", presse: "/presse"},
        //     ]},
        //     {nom:' EAR 2024 - yamoussokro', subPaths:[
        //         {photo:"/photo",  video: "/video", presse: "/presse"},
        //     ]},
        //     {nom:' calendrier', path:'/calendrier', subPaths:[]},
        // ]},
        {nom:'actualité',  sub:[
            {img: a1 , nom: categorie[0].label , path:`/actualite/${categorie[0].id}/all`},
            {img: a2 , nom:categorie[1].label, path:`/actualite/${categorie[1].id}/all`},
            {img: a3 , nom:categorie[2].label, path:`/actualite/${categorie[2].id}/all`},
            {img: a4 , nom:categorie[3].label, path:`/actualite/${categorie[3].id}/all`},
        ]},
        {nom:'inscription',sub:[
            {img: i1, nom:'participant', path:'/inscription/participant'},
            {img: i2, nom:'partenaire', path:'/inscription/partenaire'},
            {img: i3, nom:'annonceur', path:'/inscription/annonceur'},
        ]},
    ]

    const [bg, setBg] = useState(false)

    const removeHoverEffect = () =>{
        const navs = document.getElementsByClassName('nav-link')
        for (let i = 0; i < navs.length; i++) {
            const nav = navs[i];
            nav.classList.add('no-event')
            setTimeout(()=> nav.classList.remove('no-event'), 200)
        }
    }

    window.addEventListener("scroll",()=>{
        if( window.scrollY > 100 ){
            setBg(true)
        }else{
            setBg(false)
        }
    })
    const [show, setShow] = useState(false)
    return (
        <header id='head' className={'fixed-top' + (bg?' pt-2 bg-black shadow pb-2 pb-md-0':'')}>
            <div className='bg-black'>
                <div className={'d-flex justify-content-between align-items-start py-2 container'}>
                    <div className='d-flex justify-content-start align-items-center'>
                        <div>
                            <div className='rounded-circle px-2 shadow d-flex justify-content-center align-items-center'>
                                <a href={'https://www.linkedin.com/company/eventifycmr/'} className='text-white text-opacity-75' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social fa-linkedin text-center fs-4'></i> </a>
                            </div>
                        </div>
                        <div>
                            <div className='rounded-circle px-2 shadow d-flex justify-content-center align-items-center'>
                                <a href={'https://instagram.com/eventautostoprallyegt_'} className='text-white text-opacity-75' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social fa-instagram text-center fs-4'></i> </a>
                            </div>
                        </div>
                        <div>
                            <div className='rounded-circle px-2 shadow d-flex justify-content-center align-items-center'>
                                <a href={'https://www.facebook.com/eventifycameroun'} className='text-white text-opacity-75' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social fa-facebook text-center fs-4'></i> </a>
                            </div>
                        </div>
                        <div>
                            <div className='rounded-circle px-2 shadow d-flex justify-content-center align-items-center'>
                                <a href={'https://www.youtube.com/@Maption'} className='text-white text-opacity-75' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social  fa-youtube fa-linkedin text-center fs-4'></i> </a>
                            </div>
                        </div>
                    </div>
                    <div className=' d-none d-md-flex justify-content-end align-items-center'>
                        <li className='mb-2 d-flex lead fs-6 align-items-center'>
                            <i className="fa fa-phone fs-5 color-primary me-2"></i>
                            <a href='tel:+237651298633' className='text-decoration-none text-white'>+237 651 298 633 </a>
                            <span className='mx-2'>/</span>
                            <a href='tel:+237674170193' className='text-decoration-none text-white'>+237 674 170 193 </a>
                        </li>
                        <li className='mb-2 d-flex lead fs-6 align-items-center ms-3'>
                            <i className="fa-regular fa-envelope fs-5 color-primary me-2"></i>
                            <a href='mailto:contact@ear-gt.com' className='text-decoration-none text-white'>contact@ear-gt.com</a>
                        </li>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row justify-content-between  align-items-center'>
                    <NavLink className={"col"} onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} to='/'>
                        <img src={img} alt='logo ear' className='navbar-brand img-brand ' />
                    </NavLink>
                    <i role='button' onClick={()=>setShow(!show)} data-bs-toggle="offcanvas" data-bs-target="#sub-nav" id="menu-button" className={'fa-bars fa d-block d-md-none text-white fa-lg pt-3 col-auto'}></i>
                    <nav className={'navbar d-none d-md-flex col-auto d-block pt-0 d-md-flex flex-reverse justify-content-end align-items-center pb-0 ' + (show ? 'show-nav shadow':'')} >
                        {
                            links.map((elt,index) => {
                                return(
                                    elt.path?(
                                        <NavLink onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} activeclassname='active' reloadDocument className={'nav-link py-3 mx-0 mx-lg-2 d-inline-block position-relative nav-link-ltr aer-link text-capitalize px-2 mb-0 fw-bold text-opacity-75 text-white '} to={elt.path} key={index} >
                                            {elt.nom}
                                        </NavLink>
                                    ):(
                                        <li role='button' onClick={()=>setShow(false)} className={'nav-link py-3 mx-0 mx-lg-2 d-inline-block position-relative nav-link-ltr aer-link text-capitalize px-2 mb-0 fw-bold text-opacity-75 text-white '} key={index} >
                                            {elt.nom}<i className='ps-1 fa fa-angle-down'></i>
                                            {
                                                !elt.lat?(
                                                    <div className='sub'>
                                                        <div className='container'>
                                                            <div className='row justify-content-center align-items-stretch'>
                                                                {
                                                                    elt.sub.map((sub,index2)=>{
                                                                        return(
                                                                            <div className='col' key={index2}>
                                                                                <NavLink to={sub.path} reloadDocument onClick={(e)=>{window.scrollTo({ top: 0, left: 0}); removeHoverEffect()}} className={' text-decoration-none text-white fs-6 text-uppercase d-flex flex-column h-100'}>
                                                                                    <div className=' d-flex justify-content-center align-items-center h-100'>
                                                                                        <img src={sub.img} alt={elt.nom + ' ' + sub.nom} className='img-fluid object-fit-cover w-100' />
                                                                                    </div>
                                                                                    {sub.nom}
                                                                                </NavLink>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                ):(
                                                    <div className='sub'>
                                                        <Tab.Container>
                                                            <div className='row justify-content-center align-items-center'>
                                                                <div className='col-auto'>
                                                                    <Nav variant="pills" className="d-flex flex-md-column">
                                                                        {elt.sub.map((sub, index3) =>(
                                                                            !sub.path?(
                                                                                <Nav.Item key={index3} className='nav-link py-0 aer-link text-capitalize fw-medium text-opacity-75 text-white' >
                                                                                    <Nav.Link eventKey={sub.nom}> {sub.nom} </Nav.Link>
                                                                                </Nav.Item>
                                                                            ):(
                                                                                <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} key={index3} to={sub.path} className={'aer-link py-1 text-capitalize fw-medium ps-3 ms-3 pb-1 mb-0 text-white'} > {sub.nom} </NavLink>
                                                                            )
                                                                        ))}
                                                                    </Nav>
                                                                </div>
                                                                <div className='col row'>
                                                                    <Tab.Content>
                                                                        {elt.sub.map((sub,index4) =>(
                                                                            <Tab.Pane eventKey={sub.nom} key={index4}>
                                                                                {
                                                                                    <div className='col row'>
                                                                                        {
                                                                                            sub.subPaths.map((paths,index5) =>(
                                                                                                <>
                                                                                                    <NavLink onClick={()=>{window.scrollTo({ top: 0, left: 0}); removeHoverEffect()}} key={index5} to={paths.photo} className='col text-white'>
                                                                                                        <img src={img2} alt={'photos'} className='img-fluid' />
                                                                                                        <span className='text-uppercase'>Photos</span>
                                                                                                    </NavLink>
                                                                                                    <NavLink onClick={()=>{window.scrollTo({ top: 0, left: 0}); removeHoverEffect()}} key={index5} to={paths.video} className='col text-white'>
                                                                                                        <img src={img2} alt={'photos'} className='img-fluid' />
                                                                                                        <span className='text-uppercase'>Vidéo</span>
                                                                                                    </NavLink>
                                                                                                    <NavLink onClick={()=>{window.scrollTo({ top: 0, left: 0}); removeHoverEffect()}} key={index5} to={paths.presse} className='col text-white'>
                                                                                                        <img src={img2} alt={'photos'} className='img-fluid' />
                                                                                                        <span className='text-uppercase'>Presse</span>
                                                                                                    </NavLink>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                }
                                                                            </Tab.Pane>
                                                                        ))}
                                                                    </Tab.Content>
                                                                </div>
                                                            </div>
                                                        </Tab.Container>
                                                    </div>
                                                )
                                            }
                                        </li>
                                    )
                                )
                            })
                        }
                        <a href='https://mahoption.com' target='_BLANK' rel='noreferrer' className={'nav-link py-3 mx-1 mx-lg-2 d-inline-block position-relative nav-link-ltr aer-link text-capitalize px-2 mb-0 fw-bold text-opacity-75 text-white '}>
                            Mahoption
                        </a>
                        <div className={'switch-language nav-link pb-3 d-inline-block position-relative  mx-3 mb-0 fw-bold text-opacity-75 text-white'} ></div>
                    </nav>
                </div>
            </div>
            <Offcanvas show={show} onHide={()=> setShow(false)} className="bg-black sub-nav-mobile text-white">
                <Offcanvas.Header closeButton closeVariant='white' className='pb-0' >
                    <Offcanvas.Title>
                        <NavLink reloadDocument className={"col"} onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} to='/'>
                            <img src={img} alt='logo eventify' className='navbar-brand img-brand ' />
                        </NavLink>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='pt-0'>
                    <Accordion>
                        {
                            links.map((elt,index) => {
                                return(
                                    elt.path?(
                                        <NavLink reloadDocument onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} activeclassname='active' className={'nav-link py-3 ms-1 ps-3 mb-0 d-inline-block position-relative nav-link-ltr aer-link text-capitalize fw-bold text-opacity-75 text-white '} to={elt.path} key={index} >
                                            {elt.nom}
                                        </NavLink>
                                    ):(
                                        !elt.lat?(
                                            <Accordion.Item eventKey={elt.nom.length} key={index}>
                                                <Accordion.Header className='text-capitalize'>{elt.nom}</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='d-flex flex-column'>
                                                        {
                                                            elt.sub.map((sub, index) =>(
                                                                <NavLink reloadDocument onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} to={sub.path} className={'nav-link aer-link text-capitalize pb-1 mb-0 text-opacity-75 text-white'} key={index}> {sub.nom} </NavLink>
                                                            ))
                                                        }
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ):(
                                            <Accordion.Item eventKey={elt.nom}>
                                                <Accordion.Header className='text-capitalize'> {elt.nom} </Accordion.Header>
                                                <Accordion.Body className='ps-4'>
                                                    <Accordion>
                                                        {
                                                            elt.sub.map((path, index) =>(
                                                                !path.path?(
                                                                    <Accordion.Item key={index} eventKey={path.nom}>
                                                                        <Accordion.Header className='text-capitalize'> {path.nom} </Accordion.Header>
                                                                        <Accordion.Body>
                                                                        <div className='d-flex flex-column'>
                                                                            {
                                                                                path.subPaths.map((paths, index) =>(
                                                                                    <>
                                                                                        <NavLink onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} to={paths.photo} className={'nav-link aer-link text-capitalize pb-1 mb-0 text-opacity-75 text-white'} key={index}>Photo</NavLink>
                                                                                        <NavLink onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} to={paths.video} className={'nav-link aer-link text-capitalize pb-1 mb-0 text-opacity-75 text-white'} key={index}>Vidéo</NavLink>
                                                                                        <NavLink onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} to={paths.presse} className={'nav-link aer-link text-capitalize pb-1 mb-0 text-opacity-75 text-white'} key={index}>Presse</NavLink>
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                ):(
                                                                    <NavLink onClick={()=>{setShow(false); window.scrollTo({ top: 0, left: 0})}} to={'/calendar'} className={'nav-link aer-link text-capitalize mt-1 ms-1 ps-3 mb-0 text-opacity-75 text-white'} key={index}>{path.nom}</NavLink>
                                                                )
                                                            ))
                                                        }
                                                    </Accordion>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    )
                                )
                            })
                        }
                        <a className={'nav-link py-3 ms-1 ps-3 mb-0 d-inline-block position-relative nav-link-ltr aer-link text-capitalize fw-bold text-opacity-75 text-white '} href='https://mahoption.com' target='_BLANK' rel='noreferrer'>
                            Mahoption
                        </a>
                        <div className={'switch-language nav-link pb-3 d-inline-block position-relative  mx-3 mb-0 fw-bold text-opacity-75 text-white'} ></div>
                    </Accordion>
                </Offcanvas.Body>
                <div className='text-end'>
                    <img className=' w-75 end-0 bottom-0' alt='Voiture rallye dorée' src={imgNav}/>
                </div>
            </Offcanvas>
        </header>
    )
}

export default Header

import React, { useState } from 'react'
import { Link } from 'react-router-dom';

// Import slides images;
import im1 from '../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_10.22.34_IMG_4565_LR.webp'
import im2 from '../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_16.02.46_IMG_5728_LR.webp';
import im3 from '../../assets/img/EA_RALLYE_ALBUM_1/1 - Rallye_2023-07-01_15.50.44_IMG_5590_LR.webp'
import im4 from '../../assets/img/EA_RALLYE_ALBUM_1/MAIRE DE DOUALA.webp'
import im5 from '../../assets/img/EA_RALLYE_ALBUM_1/3 - Golf_IMG_6838_LR.webp';


// Import video and photo of participants

import jacque from "../../assets/video/partner/TEASER-EVENTIFY-AUTOSTOP-JACQUE-NJAMBA.webm"
import michael from "../../assets/video/partner/TEASER-EVENTIFY-AUTOSTOP-MICHAEL-MAMA.webm"
import jing from "../../assets/video/partner/TEASER-EVENTIFY-AUTOSTOP-PAUL-JING.webm"
import phillipe from "../../assets/video/partner/TEASER-EVENTIFY-AUTOSTOP-PHILLIPPE-DOUCET.webm"
import herve from "../../assets/video/partner/TEASER-EVENTIFY-AUTOSTOP-HERVE-AMBATA.webm"
import patrick from "../../assets/video/partner/TEASER EVENTIFY AUTOSTOP-PATRICK LESBOIS.webm"
import stanley from "../../assets/video/partner/TEASER EVENTIFY AUTO'STOP-STANLEY ENOW-.mp4"
import jacqueI from "../../assets/img/partner/jacques.webp"
import michaelI from "../../assets/img/partner/Michael  MAMA DIRECTEUR D'EXPLOITATION PAK.webp"
import stanleyI from "../../assets/img/partner/Stanley_Enow_picture.webp"
import patrickI from "../../assets/img/partner/patrick.webp"
import jingI from "../../assets/img/partner/paul_jing.webp"
import philippeI from "../../assets/img/partner/philipe.webp"
import herveI from "../../assets/img/partner/herve.webp"


import Title from '../../components/ui/Title';
import Modal from 'react-responsive-modal';
import Carousel from 'react-multi-carousel';

const EarKribi = () => {

    const [open, setOpen] = useState(false)
    const [vids, setVids] = useState(false)

    const links = [
        {icon: "fa-regular fa-folder-open", title: "Album photo", path:"/Album_photo"},
        {icon: "fa-regular fa-newspaper", title: "Revue de presse", path:"https://fr.allafrica.com/stories/202306290205.html"},
        {icon: "fa fa-film", title: "vidéo", path:"https://youtu.be/nrCj7i2f_sw?si=3OaVnYYVTB9UWvlp"}
    ]

    const imgRallye=[
        {img: im1,},
        {img: im2,},
        {img: im3,},
        {img: im4,},
        {img: im5,},
    ]

    const responsiveVids = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet:{
            breakpoint: { max: 1200, min: 767 },
            items: 1
        },
        mobilet:{
            breakpoint: { max: 972, min: 380 },
            items: 1
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 1
        },
    }

    const person = [
        {imag:jingI ,img:'6', nom:'paul jing', role:"Avocat d'affaires", vid:jing},
        {imag:jacqueI ,img:'1', nom:'Jacques Ndjamba', role:"Homme d'affaires", vid:jacque},
        {imag:stanleyI ,img:'3', nom:'stanley enow', role:'Artiste', vid:stanley},
        {imag:michaelI ,img:'2', nom:'Michael Mama', role:"Directeur d'exploitation du Port de Kribi", vid:michael},
        {imag:herveI ,img:'4', nom:'herve ambata', role:'Directeur de la Police Municipale', vid:herve},
        {imag:philippeI ,img:'7', nom:'philippe doucet', role:"Journaliste Sportif Canal+", vid:phillipe},
        {imag:patrickI ,img:'5', nom:'patrick lesbois', role:'Directeur Géneral Chrono Service', vid:patrick},
    ]

    const responsive = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet:{
            breakpoint: { max: 1200, min: 767 },
            items: 3
        },
        mobilet:{
            breakpoint: { max: 992, min: 380 },
            items: 2
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 1
        },
    }

    return (
        <>
            <section>
                <Carousel responsive={responsiveVids} infinite={true} autoPlay={true} autoPlaySpeed={2000} >
                {
                    imgRallye.map((elt, index)=>{
                        return(
                            <div key={index} className='hero-slide'>
                                <div className=' position-relative d-flex justify-content-center'>
                                    <div className=' position-absolute w-100 h-100 bg-dark bg-opacity-25'></div>
                                    <img alt='Event AutoStop Rallye GT 2023' src={elt.img} className='img-fluid img' />
                                </div>
                            </div>
                        )
                    })
                }
                </Carousel>
            </section>

            <div className='container'>
                <div id='home-team'>
                    <Title text={"Participants de l'édition Kribi 2023"} />
                    <Carousel responsive={responsive} showDots={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='container bg-transparent' >
                            {
                                person.map((elt,index)=>(
                                    <div key={index} className='px-2 mb-4 position-relative h-100'>
                                        <div className='rounded-3 mb-3 border border-1 border-light bg-transparent card shadow-sm'>
                                            <div className='team-img'>
                                                <div className='aer-img-team-mobile overflow-hidden'>
                                                    <img src={elt.imag} alt={elt.nom+ " " + elt.role} className='rounded-circle' />
                                                </div>
                                            </div>
                                            <div className='px-1 gx-0 card-body text-center team-card mb-5'>
                                                <h5 className='text-light text-uppercase'>{elt.nom}</h5>
                                                <span className='color-primary text-center'>{elt.role}</span>
                                                <br/>
                                                <i role='button' className='fa-brands fa-youtube text-light fa-2x mb-sm-3 mb-1 btn-vid bg-transparent ' onClick={()=>{setOpen(true); setVids(elt.vid)}}></i>
                                            </div>
                                        </div>

                                    </div>
                                ))
                            }
                    </Carousel>
                </div>
            </div>

            <div className='container '>
                <div className='mx-auto row justify-content-center'>
                    <Title text={"Encore plus sur l'édition Kribi 2023"} />
                    {
                        links.map((elt, index) =>(
                            <div className='col-xs-6 col mb-2 my-2 col-md-4' key={index}>
                                <Link to={elt.path} className='text-decoration-none' >
                                    <div className='adress-card bg-white shadow rounded border border-2 border-white p-2 text-dark text-center'>
                                        <div className=' w-auto mb-3'>
                                            <i className={ elt.icon + " gradient-back fs-1 mx-3 border border-2 py-3 rounded-circle py-4 px-4"}></i>
                                        </div>
                                        <small className='fw-bold text-uppercase text-dark'>{elt.title}</small>
                                    </div>
                                </Link>
                            </div>
                        ))
                    }
                </div>


                <div className='mt-5 pt-3'>
                    {/* <iframe className='fp-iframe' allowFullScreen="allowfullscreen" scrolling="no" src="https://heyzine.com/flip-book/219c03b270.html#page/2" width="100%" height="800px" title="Event Auto'Stop Rallye GT" ></iframe> */}
                    <iframe className='fb-iframe' src="https://online.fliphtml5.com/ssivn/pdvt/" width="100%" height="800px"  seamless="seamless" scrolling="no" frameborder="0" allowtransparency="true" allowFullScreen="true" title="Event Auto'Stop Rallye GT"></iframe>
                </div>
            </div>


            <Modal open={open} onClose={()=>{setOpen(false)}} center={true} className='bg-transparent' showCloseIcon={true}>
                <div className=' d-flex justify-content-center align-items-center my-3'>
                    <video loop controls autoPlay className=' img-fluid'>
                        <source type='video/mp4' src={vids}/>
                    </video>
                </div>
            </Modal>
        </>
    )
}

export default EarKribi

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './components/style/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/vendor/fontawesome/css/all.min.css'
import './assets/vendor/remixicon/remixicon.css'
import "react-multi-carousel/lib/styles.css"
import 'react-responsive-modal/styles.css';
import Home from './views/Home';
import Header from './components/ui/Header';
import Footer from './components/ui/Footer';
import EarKribi from './views/Events/EarKribi';
import Kribi2023 from './views/albums/Kribi2023';
import 'react-quill/dist/quill.snow.css';
import RegisterParticipant from './views/Register/RegisterParticipant';
import Contexte from './views/about/Contexte';
import Members from './views/about/Members';
import NotFound from './views/NotFound';
import News from './views/News';
import { useContext } from 'react';
import { AppContexte } from './context/context';
import Loading from './views/Loading';
import Reload from './views/Reload';
import RegisterPartner from './views/Register/RegisterPartner';
import RegisterAdvertiser from './views/Register/RegisterAdvertiser';
import Soon from './views/Soon';
import EarJapoma2024 from './views/Events/EarJapoma2024';
import Japoma2024 from './views/albums/Japoma2024';
import Sponsor from './views/Sponsor';

const App = () => {


  const { load, loadingErr } = useContext(AppContexte)

  return (
    <BrowserRouter>
    {
      !load?(
        <Loading/>
      ):loadingErr?(
        <Reload/>
      ):
      <>
        <Header/>
        <Routes>
          <Route path='/'>
            <Route index element={<Home/>} />
            <Route path='contexte' element={<Contexte/>} />
            <Route path='membres' element={<Members/>} />
            <Route path='event/ear-kribi-2023' element={<EarKribi/>} />
            <Route path='event/ear-japoma-2024' element={<EarJapoma2024/>} />
            <Route path='Album_photo' element={<Kribi2023/>} />
            <Route path='japoma/album_photo' element={<Japoma2024/>} />
            <Route path='inscription/participant' element={<RegisterParticipant/>} />
            <Route path='/inscription/partenaire' element={<RegisterPartner/>} />
            <Route path='/inscription/annonceur' element={<RegisterAdvertiser/>} />
            <Route path='actualite/:catId/:actuId' element={<News/>} />
            <Route path='/sponsors' element={<Sponsor/>} />
            <Route path='/soon' element={<Soon/>} />
            <Route path='*' element={<NotFound/>} />
          </Route>
        </Routes>
        <Footer/>
      </>
    }
  </BrowserRouter>

  );
}

export default App;

import React, { useContext } from 'react'
import Title from '../components/ui/Title'
import SponsorCard from '../components/ui/SponsorCard'
import { AppContexte } from '../context/context'
import axios from 'axios'
import { URL_API_SPONSORS } from '../constant/url'
import { HashLoader } from 'react-spinners'

const Sponsor = () => {

    const { loadSponsor, setLoadSponsor, sponsor, setSponsor, errorSponsor, setErrorSponsor } = useContext(AppContexte)

    const reload = async() =>{
        setLoadSponsor(true)
        axios
        .get(URL_API_SPONSORS)
        .then((response)=>{setSponsor(response.data.data); setLoadSponsor(false); setErrorSponsor(false)})
        .catch((err) => {setLoadSponsor(false); setErrorSponsor(true); console.log(err.message);})
    }

    const reading = {
        display : "flex",
        margin : "0 auto"
    }

    return (
        <div className='container pt-5'>
            <div className='pt-5'>
                <Title text="Nos partenaires EAR Rallye GT" />
                <div className=' row justify-content-center align-items-center mt-4'>
                    {
                        loadSponsor?
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                            <div className='text-center'>
                                <HashLoader color='#fbb03b' size={60} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                                <p className='mt-4'>Veuillez patienter...</p>
                            </div>
                        </div>
                        :
                        errorSponsor?
                            <div className='w-100 h-100 d-flex justify-content-center align-items-center mb-5'>
                                <div className='text-center'>
                                    <i className='ri-error-warning-fill ri-5x text-danger'></i>
                                    <p className=''>Une erreur c'est produite. <br/> Veuillez vérifier votre connexion internet et réessayer ! </p>
                                    <button onClick={reload} className='btn bg-transparent border rounded-circle'><i className=' ri-loop-right-line px-1 ri-3x'></i></button>
                                </div>
                            </div>
                        :
                        sponsor?.length ?
                            sponsor.map((elt,index) =>(
                                <SponsorCard img={elt.image} nom={elt.name} site={elt.lien} key={index} />
                            ))
                        :
                        <div className='w-100 h-100 d-flex justify-content-center align-items-center mb-5'>
                            <div className='text-center'>
                                <i className='ri-error-warning-fill ri-5x text-danger'></i>
                                <p className=''>Une erreur c'est produite. <br/> Veuillez vérifier votre connexion internet et réessayer ! </p>
                                <button onClick={reload} className='btn bg-transparent border rounded-circle'><i className=' ri-loop-right-line px-1 ri-3x'></i></button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Sponsor
import React from 'react'
import img1 from '../../assets/img/contexteImg.jpeg'
import img2 from '../../assets/img/trio-gagnant-EAR-2023.webp'
import Title from '../../components/ui/Title'
import MetaDecorator from '../../components/ui/MetaDecorator'

const Contexte = () => {
    return (
        <>
            <div className='py-5'></div>
            <div className='container'>
                <Title text="Contexte"/>
                <div className='row mb-4 justify-content-center align-items-center'>
                    <div className=' col-lg-5 col-12'>
                        <img src={img1} alt='Sponsor' className='img-fluid'/>
                    </div>
                    <div className=' col-lg-7 col-12'>
                        <p className='context-text'>
                        L'agence de production événementielle Mahoption et le magazine spécialisé dans l'actualité automobile; Auto'stop Magazine, se sont réunis les 1er et 02 juillet 2023 pour organiser le tout premier Event AutoStop Rallye GT (EAR). Nous avons eu l’honneur de compter sur l'appui de la Communauté Urbaine de Kribi (CUK), de la préfecture, des FMO et de nos précieux sponsors voici un petit débriefing pour vous situer les temps forts de cette journée.
                        </p>
                    </div>
                </div>
                <div className='row mb-4 justify-content-center align-items-center'>
                    <div className=' col-lg-5 col-12'>
                        <img src={img2} alt='Sponsor' className='img-fluid'/>
                    </div>
                    <div className=' col-lg-7 col-12'>
                        <p className='context-text'>
                        EAR a vu le jour à Kribi. La course a démarré sur l’esplanade de l’hôtel de ville avec la présence des autorités et invités parmi lesquels l'Inspectrice Générale de la Mairie qui a prononcé le discours d'ouverture. Le départ de la compétition s’est effectué avec 08 participants sous l’œil aiguisé des commissaires de course. Afin de garantir l’objectivité et le sérieux des temps de passage, nous avons compté sur l'ingénierie de Camtrack pour le chronométrage géolocalisé des bolides. La course s’est soldée par l'arrivée de Mr Junior EPEE (Shooting Star) en troisième position, Mr Jacques NDJAMBA (Accent Media / YooMee) en seconde et enfin Mr Paul JING grand gagnant de cette 1er édition!
                        </p>
                    </div>
                </div>
            </div>

            <MetaDecorator
                description={"Site web de Event Auto'Stop Rallye GT"}
                title={"Contexte"}
                imageUrl={"https://ear-admin.eventify.cm/static/media/LOGO%20EAR%202024-B.878af34127719117fd68.png"}
                imageAlt={"EAR"}
            />
        </>
    )
}

export default Contexte

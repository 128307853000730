import React, { useState } from 'react'
import vid from "../../assets/video/VID_EAR_2024.mp4"

const Hero = () => {
    const style ={
        marginTop: "-2rem",
        zIndex: "10"
    }

    const [mute, setMute] = useState(true)

    return (
        <div className="h-auto container position-relative mt-4 pt-3">
            <video autoPlay muted={mute} loop className='hero-vid'>
                <source src={vid} type='video/mp4' />
            </video>
            <div className="d-flex justify-content-end me-3">
                <i type="button" style={style} className = {"text-end fa-xl" + (mute ? ' fa fa-volume-xmark' : ' fa fa-volume-high')} onClick={()=> setMute(!mute)} ></i>
            </div>
        </div>
    )
}

export default Hero

import React from 'react'

const NotFound = () => {
    return (
        <div className='py-5 d-flex justify-content-center align-items-center overflow-hidden'>
            <div className='text-center mt-5'>
                <i className='ri-error-warning-fill ri-10x text-danger'></i>
                <h1 className='mt-4'> {'404'} </h1>
                <p>Page introuvable</p>
            </div>
        </div>
    )
}

export default NotFound
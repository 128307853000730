import React from 'react'
import { HashLoader } from 'react-spinners'

const reading = {
    display : "flex",
    margin : "0 auto"
}
const Loading = () => {
    return (
        <div className='loading-page d-flex justify-content-center align-items-center'>
            <div className='text-center'>
                <HashLoader color='#fbb03b' size={90} cssOverride={reading} aria-label='Loading spinner' data-testid='loader' className='text-center ps-3'/>
                <p className='mt-4'>Veuillez patienter...</p>
            </div>
        </div>
    )
}

export default Loading
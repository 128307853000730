import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { AppContexte } from '../../context/context'
import { URL_API } from '../../constant/url'

const SearchBar = () => {

    const { actu, categorie } = useContext(AppContexte)

    const [overlay, setOverlay] = useState(false)
    const [value, setValue] = useState('')
    const [result, setResult] = useState([])

    const handleClick = (e) =>{
        if (e.target.classList.contains("dismiss")) {
            setOverlay(false)
        }
    }

    useEffect(()=>{
        const searchResult = actu.length? actu.filter((item) =>{
            let label
            categorie.map((elt) => elt.id === item.categorie_id ? label= elt.label : null)
            return(
                item.title.toLowerCase().includes(value.toLowerCase()) ||
                item.subtitle.toLowerCase().includes(value.toLowerCase()) ||
                item.text.toLowerCase().includes(value.toLowerCase()) ||
                label.toLowerCase().includes(value.toLowerCase()) ||
                item.createdAt.toLowerCase().includes(value.toLowerCase())
            )
        }):null
        setResult(searchResult);
    },[actu, value, categorie])





    return (
        <div className='ms-3 my-1'>
            <div onClick={()=>setOverlay(true)} role='button' className=' px-2 py-1 bg-white rounded-pill'>
                <span className=' px-3 text-black text-opacity-75'>Rechercher ...</span>
                <i className="fa-solid fa-magnifying-glass text-black"></i>
            </div>

            {
                overlay ?
                <div className='overlay dismiss' onClick={handleClick}>
                    <div className='container'>
                        <div className='bg-white px-4 py-2 position-relative'>
                            <div className='position-absolute end-0 me-4' role='button' onClick={()=>setOverlay(false)}>
                                <i className="fa-solid fa-xmark fa-2x text-black"></i>
                            </div>
                            <div className='mt-5 d-flex align-items-center p-2 border rounded-3'>
                                <input value={value} onChange={(e)=>setValue(e.target.value)} type='search' placeholder='Tapez votre recherche' className='border-0 form-control rounded-0 border-bottom outline-0'/>
                            </div>
                            <div id='results' className='mt-3'>
                                {
                                    !value ?
                                        <small className='text-center mx-auto text-black text-opacity-75'>Saisir votre recherche</small>:
                                    !result || !result.length ? 
                                        <small className='text-center mx-auto d-flex flex-column  text-black text-opacity-75'>
                                            <i className="fa-solid fa-circle-exclamation fa-3x"></i>
                                            Aucun résultat trouvé
                                        </small> :
                                    result.map((elt, index) =>{
                                        return(
                                            <NavLink to={`/actualite/${elt.categorie_id}/${elt.id}`} onClick={()=>{setOverlay(false); window.scrollTo({ top: 0, left: 0})}} className='col-12 d-flex justify-content-start align-items-center bg-white rounded-4 border py-1 ps-1 my-1 part-show' key={index}>
                                                <div className='img-show col-auto d-flex justify-content-center align-items-center overflow-hidden rounded-start-3 pe-2'>
                                                    <img src={`${URL_API}${elt.image}`} alt={elt.title +" "+ elt.subtitle} className='img-fluid' />
                                                </div>
                                                <div className='text-end'>
                                                    <h3 className='text-uppercase text-start title-show m-0 text-black'> {elt.title} </h3>
                                                    <h6 className='color-primary text-start title-show fw-light m-0'> {elt.subtitle} </h6>
                                                </div>
                                            </NavLink>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>:null
            }
        </div>
    )
}

export default SearchBar
import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <div  id='footer' className='bg-black'>
            <div className='bg-black pt-5 bg-foot position-relative overflow-hidden'>
                <div className='container footer'>
                    <div className='row justify-content-between align-items-start mb-2'>
                        <div className='col-md-4 mt-md-0 mt-5 col-12'>
                            <h3 className='pb-4'> Nous contacter</h3>
                            <ul className=' list-unstyled'>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa fa-phone fs-5 color-primary me-3"></i>
                                    <div className='d-flex flex-column'>
                                        <a href='tel:+237651298633' className='text-decoration-none text-white'>+237 651 298 633 </a>
                                        <a href='tel:+237674170193' className='text-decoration-none text-white'>+237 674 170 193 </a>
                                    </div>
                                </li>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa-regular fa-envelope fs-5 color-primary me-3"></i>
                                    <a href='mailto:contact@ear-gt.com' className='text-decoration-none text-white'>contact@ear-gt.com</a>
                                </li>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa fa-location-dot fs-5 color-primary me-4"></i>
                                    <a target="_blank" rel='noreferrer' href='https://maps.app.goo.gl/4MPpqXczKmVdTcoA9' className='text-decoration-none text-white'>Energy Club Bonapriso, Douala - Cameroun</a>
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-4 mt-md-0 mt-5 col-12'>
                            <h3 className='pb-4'>Liens utiles</h3>
                            <ul className='list-unstyled'>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa fa-angles-right fs-6 color-primary me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/event/ear-kribi-2023'} className='text-decoration-none text-white'>EAR 2023 Kribi </NavLink>
                                </li>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa fa-angles-right fs-6 color-primary me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/actualite/2/all'} className='text-decoration-none text-white'>Sport mécanique</NavLink>
                                </li>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa fa-angles-right fs-6 color-primary me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/actualite/1/all'} className='text-decoration-none text-white'>Bussiness</NavLink>
                                </li>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa fa-angles-right fs-6 color-primary me-3"></i>
                                    <NavLink onClick={()=>window.scrollTo({ top: 0, left: 0})} to={'/inscription/participant'} className='text-decoration-none text-white'>Inscription / Partenaire</NavLink>
                                </li>
                                <li className='mb-2 d-flex lead fs-6 align-items-center'>
                                    <i className="fa fa-angles-right fs-6 color-primary me-3"></i>
                                    <a className='text-decoration-none text-white' href='https://mahoption.com' target='_BLANK' rel='noreferrer' >Mahoption</a>
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-4 mt-md-0 mt-5 col-12'>
                            <h3 className='pb-4'>Réseaux Sociaux</h3>
                            <div className='d-flex justify-content-start align-items-start py-2'>
                                <div>
                                    <div className='rounded-circle mx-2 px-1 border border-1 border-white py-2 px-2 shadow d-flex justify-content-center align-items-center'>
                                        <a href={'https://www.linkedin.com/company/eventifycmr/'} className=' color-primary' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social fa-linkedin text-center fs-4'></i> </a>
                                    </div>
                                </div>
                                <div>
                                    <div className='rounded-circle mx-2 px-1 border border-1 border-white py-2 px-2 shadow d-flex justify-content-center align-items-center'>
                                        <a href={'https://instagram.com/eventautostoprallyegt_'} className=' color-primary' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social fa-instagram text-center fs-4'></i> </a>
                                    </div>
                                </div>
                                <div>
                                    <div className='rounded-circle mx-2 px-1 border border-1 border-white py-2 px-2 shadow d-flex justify-content-center align-items-center'>
                                        <a href={'https://www.facebook.com/eventifycameroun'} className=' color-primary' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social fa-facebook text-center fs-4'></i> </a>
                                    </div>
                                </div>
                                <div>
                                    <div className='rounded-circle mx-2 px-1 border border-1 border-white py-2 px-2 shadow d-flex justify-content-center align-items-center'>
                                        <a href={'https://www.youtube.com/@Maption'} className=' color-primary' target='_blank' rel='noreferrer' > <i className='fa-brands eventify-social  fa-youtube fa-linkedin text-center fs-4'></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='gif' >
                    <div className='car'></div>
                </div>
            </div>



            <div className='container pb-3'>
                <hr/>
                <small> Copyright &copy; 2023 Mahoption. Tous Droits Reservés </small>
            </div>
        </div>
    )
}

export default Footer

import React, { useState } from 'react'
import { Tab, Nav, Col, Row } from "react-bootstrap";
import Carousel from  'react-multi-carousel'
import { useForm } from 'react-hook-form';
import SubmitModal from '../../components/ui/SubmitModal'
import { ClipLoader } from 'react-spinners'
import jingI from "../../assets/img/members/paulJing.webp"
import jacqueI from "../../assets/img/members/jacques.webp"
import hilaireI from "../../assets/img/members/Hilaire_Tinene.webp"
import patrickI from "../../assets/img/members/patrick.webp"
import juniorI from "../../assets/img/members/junior.webp"
import joelI from "../../assets/img/members/Joel.webp"
import profile from "../../assets/img/profile.webp"
import logo from "../../assets/img/LOGO EAR 2024-W.png"
import logoB from "../../assets/img/LOGO EAR 2024-B.png"
import Title from '../../components/ui/Title';
import MetaDecorator from '../../components/ui/MetaDecorator';




const MemberCard = (props) =>{
    return(
        <>
            <div className='mb-5 px-md-2 px-3 member-card'>
                <div className='rounded-5 border border-1 border-white border-opacity-50 py-5 px-3 row justify-content-around align-items-center'>
                    <div className='col'>
                        <img src={props.img} alt={""} className='img-fluid w-75 border border-5 border-white rounded-circle' />
                    </div>
                    <div className='col'>
                        <img src={logo} alt={""} className=' img-fluid rounded-circle' />
                    </div>
                </div>
                <div className='mt-2'>
                    <h4 className='m-0'> {props.nom} </h4>
                    <h5 className='color-primary m-0'> {props.function} </h5>
                    <small className=' text-uppercase'> {props.sub} </small>
                </div>
            </div>
        </>
    )
}

const Members = () => {

    const cards = [
        {img:jingI , nom:"Paul Jing", function:"Avocat d'affaires", sub:"Jing & Partners"},
        {img:jacqueI , nom:"Jacques Ndjamba", function:"Homme d'affaire", sub:"PCA Accent média et Yoomee"},
        {img:hilaireI , nom:"Hilaire Tinene", function:"Homme d'affaire", sub:"PCA CAMTRACK"},
        {img:juniorI , nom:"Junior Epee", function:"Promoteur Immobilier", sub:"Fondateur Shooting Star"},
        {img:joelI , nom:"Joël Tchamako", function:"Propriétaire garage", sub:"PDG Gap Motors"},
        {img:patrickI , nom:"Patrick Lebois", function:"Architecte", sub:"Directeur géneral chrono service"},
    ]

    const responsive = {
        superLargeDesktop:{
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop:{
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet:{
            breakpoint: { max: 1200, min: 767 },
            items: 1
        },
        mobilet:{
            breakpoint: { max: 972, min: 380 },
            items: 1
        },
        mobiles:{
            breakpoint: { max: 525, min: 0 },
            items: 1
        },
    }

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [tel, setTel] = useState('')
    const [country, setCountry] = useState('')
    const [genre, setGenre] = useState('Homme')
    const [type, setType] = useState('Black')
    const [read, setRead] = useState(false)
    const [ok, setOk] = useState(false)
    const [err, setErr] = useState(false)


    // eslint-disable-next-line
    const emailPattern= /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    const sendEmail = (e) =>{

        if (window.Email) {
            setRead(true)
            window.Email.send({
                SecureToken: "569fda13-471b-447c-9a3a-e813c707ab2a",
                To : 'contact@ear-gt.com',
                From : 'contact@ear-gt.com',
                Subject : "DEMAMANDE D'ADHESION MEMBRE EAR",
                Body :`<h1><strong>TYPE D'ADHESION :  ${type} </strong></h1>  <br/><strong>NOM :</strong>  ${name} <br/><strong>EMAIL :</strong>  ${email} <br/><strong>TELEPHONE :</strong>  ${tel} <br/><strong>PAYS :</strong>  ${country} <br/><strong>CIVILITE :</strong>  ${genre} <br/>`
            }).then(message =>{
                console.log(message);
                setRead(false);
                message === 'OK' ? setOk(true): setErr(true)
            })
        }
    }


    const {register, formState: { errors }, handleSubmit} = useForm();



    const reading = {
        display : "flex",
        margin : "0 auto"
    }

    return (
        <>
            <div className='p-5 m-3'></div>
            <div className='container'>
                <Tab.Container defaultActiveKey={'1'}>
                    <Row className='position-relative'>
                        <Col sm={12} className='position-sticky nav-member bg-black py-2'>
                            <Nav variant="pills" className="justify-content-center position-relative">
                                <Nav.Item>
                                    <Nav.Link className='m-0 px-md-1 w-100' eventKey={'1'}>Comité d'honneur</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='m-0 px-md-1 w-100' eventKey={'2'}>Adhérer au club</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={12} >
                            <Tab.Content className = 'mx-auto'>
                                <Tab.Pane eventKey={'1'} >
                                    <div>
                                        <Title text="le club" />
                                        <div className='row justify-content-center align-items-center d-none d-md-flex'>
                                                {
                                                    cards.map((elt, index) =>{
                                                        return(
                                                            <div className='col-lg-4 col-md-6 col-12' key={index} >
                                                                <MemberCard img={elt.img} nom={elt.nom} function={elt.function} sub={elt.sub} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                        </div>
                                        <div className='d-block d-md-none'>
                                            <Carousel responsive={responsive} showDots={true} autoPlay={true} autoPlaySpeed={4000} infinite={true} className='container' >
                                                {
                                                    cards.map((elt, index) =>{
                                                        return(
                                                            <MemberCard key={index} img={elt.img} nom={elt.nom} function={elt.function} sub={elt.sub} />
                                                        )
                                                    })
                                                }
                                            </Carousel>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey={'2'} >
                                    <div>
                                        <Title text="demande d'adhésion" />
                                        <span>Pour faire une demande d'adhésion, veuillez répondre à toutes les questions.</span>
                                        
                                        <form onSubmit={handleSubmit(sendEmail)}>
                                            <div className='row justify-content-start align-items-stretch'>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='inputBox'>
                                                        <input {...register('name',{required: true})} onChange={(e)=>setName(e.target.value)} value={name} type={'text'} className='w-100' required />
                                                        <span>Nom complet</span>
                                                        <i></i>
                                                    </div>
                                                    {errors.name && <small className="text-danger" role="alert">Nom requis</small>}
                                                </div>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='inputBox'>
                                                        <input {...register('email',{required: true, pattern: emailPattern })} onChange={(e)=>setEmail(e.target.value)} value={email} type={'email'} className='w-100' required />
                                                        <span>Email</span>
                                                        <i></i>
                                                    </div>
                                                    {errors.email && <small className="text-danger" role="alert">Email requis</small>}
                                                </div>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='inputBox'>
                                                        <input {...register('tel',{required: true})} onChange={(e)=>setTel(e.target.value)} value={tel} type='number' className='w-100' required />
                                                        <span>Nº Téléphone</span>
                                                        <i></i>
                                                    </div>
                                                    {errors.tel && <small className="text-danger" role="alert">Numéro de téléphone requis</small>}
                                                </div>
                                                <div className='col-md-6 col-12 mb-2'>
                                                    <div className='inputBox'>
                                                        <input {...register('country',{required: true})} onChange={(e)=>setCountry(e.target.value)} value={country} type='country' className='w-100' required />
                                                        <span>Pays</span>
                                                        <i></i>
                                                    </div>
                                                    {errors.tel && <small className="text-danger" role="alert">Pays requis</small>}
                                                </div>
                                                <div className=' col-12 mb-2 d-flex align-items-start mt-3'>
                                                    <p className='me-3 py-1'>Civilité:</p>
                                                    <div className='pe-3 py-1 ps-2 border rounded me-3'>
                                                        <input type='radio' id='homme' name='sexe' value={'Homme'} onChange={(e)=>setGenre(e.target.value)} defaultChecked className='form-check-input form-check-warning me-2'/>
                                                        <label for='homme'>Homme</label>
                                                    </div>
                                                    <div className='pe-3 py-1 ps-2 border rounded me-3'>
                                                        <input type='radio' id='femme' name='sexe' value={'femme'} onChange={(e)=>setGenre(e.target.value)} className='form-check-input form-check-warning me-2'/>
                                                        <label for='femme'>Femme</label>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-2 mt-3 row justify-content-center'>
                                                    <p className='mt-4 col-12 mb-3'>Type d'adhésion:</p>
                                                    <div className=' col-12 row justify-content-start align-items-center'>
                                                        <div className='col-md-4 col-12 mx-2 d-flex mb-4'>
                                                            <input type='radio' id='silver' name='type' value={'Silver'} onChange={(e)=>setType(e.target.value)} defaultChecked className='input-hidden col-auto form-check-input form-check-warning me-2'/>
                                                            <label for='silver'>
                                                                <div className='row justify-content-center align-items-center pb-2 rounded-5'>
                                                                    <span>Silver Card à 150 000 FCFA l'an</span>
                                                                    <div className='rounded-5 border border-1 border-white border-opacity-50 py-5 px-3 row justify-content-around align-items-center w-100 silver-card'>
                                                                        <div className='col'>
                                                                            <img src={profile} alt={""} className='img-fluid w-75 border border-5 border-white rounded-circle' />
                                                                        </div>
                                                                        <div className='col'>
                                                                            <img src={logoB} alt={""} className=' img-fluid rounded-circle' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className='col-md-4 col-12 mx-2 d-flex mb-4'>
                                                            <input type='radio' id='black' name='type' value={'Black'} onChange={(e)=>setType(e.target.value)} className='input-hidden col-auto form-check-input form-check-warning me-2'/>
                                                            <label for='black'>
                                                                <div className='row justify-content-center align-items-center pb-2 rounded-5'>
                                                                    <span>Black Card à 300 000 FCFA l'an</span>
                                                                    <div className='rounded-5 border border-1 border-white border-opacity-50 py-5 px-3 row justify-content-around align-items-center w-100'>
                                                                        <div className='col'>
                                                                            <img src={profile} alt={""} className='img-fluid w-75 border border-5 border-white rounded-circle' />
                                                                        </div>
                                                                        <div className='col'>
                                                                            <img src={logo} alt={""} className=' img-fluid rounded-circle' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mb-5'>
                                                {
                                                    read?<button disabled className='btn d-flex bg-transparent border border-2 border-warning'><ClipLoader 
                                                    color='#fbb03b' 
                                                    size={30} 
                                                    cssOverride={reading} 
                                                    aria-label='Loading spinner' 
                                                    data-testid='loader' 
                                                    className='text-center'/> Patientez </button> :
                                                    ok? <SubmitModal open={ok} setOpen={setOk} message={"Demande envoyéee avec success"} class={"ri-checkbox-circle-fill text-success"} classColor={"text-success"} />:
                                                    err? <SubmitModal open={err} setOpen={setErr} message={"Erreur survenu lors de l'envoie"} class={"ri-close-circle-fill text-danger"} classColor={"text-danger"} />:
                                                    <input type='submit' value={"Soumettre"} className='form-control w-auto fw-bold me-3'/>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>




            <MetaDecorator
                description={"Site web de Event Auto'Stop Rallye GT"}
                title={"Membres"}
                imageUrl={"https://ear-admin.eventify.cm/static/media/LOGO%20EAR%202024-B.878af34127719117fd68.png"}
                imageAlt={"EAR"}
            />
        </>
    )
}

export default Members

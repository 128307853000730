import React from "react";
import { Helmet } from "react-helmet";


const MetaDecorator = ({ title, description, imageUrl }) => (
    <Helmet>
        <meta property="og:image:url" content={imageUrl} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:secure" content={imageUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:url" content={"https://ear-gt.com" + window.location.pathname + window.location.search} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="ear-gt.com" />
        <meta property="twitter:url" content={"https://ear-gt.com" + window.location.pathname + window.location.search} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image:url" content={imageUrl} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:image:secure" content={imageUrl} />


    </Helmet>
    );

export default MetaDecorator;